import React from "react";
import AvailableRewards from "components/Dashboard/Referral/AvailableRewards/AvailableRewards";
import ReferralList from "components/Dashboard/Referral/ReferralList/ReferralList";
import UniqueReferralLink from "components/Dashboard/Referral/UniqueReferralLink/UniqueReferralLink";
import classes from "./Referral.module.css";

const Referral = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.container}>
        <AvailableRewards />
        <UniqueReferralLink />
      </div>
      <ReferralList />
    </section>
  );
};

export default Referral;
