import React from "react";
import classes from "./FlexibleDepositPlan.module.css";
import clsx from "clsx";
import { Button, Heading, SectionHeading, Text } from "components/common";
import Plans from "./Plans/Plans";

const FlexibleDepositPlan = ({ homepage_contents }) => {
  let { flexible_plans } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <SectionHeading>{flexible_plans.title}</SectionHeading>
          <Heading xl6>
            {flexible_plans.heading}
          </Heading>
          <Text xl2 primitive800>
            {flexible_plans.subtitle}
          </Text>
        </div>

        <Plans />
      </div>
    </section>
  );
};

export default FlexibleDepositPlan;
