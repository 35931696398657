import React, { useRef, useState } from "react";
import classes from "./EnterEmailOrPhone.module.css";
import Header from "components/Athentication/Header/Header";
import { Button, Input } from "components/common";
import PhoneNumberDropdown from "components/common/PhoneNumberDropdown/PhoneNumberDropdown";

import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { handleKeyDown } from "hooks";

const EnterEmailOrPhone = ({
  selectedMethod,
  email,
  phone,
  setEmail,
  setPhone,
  selectedCountryCode,
  setSelectedCountryCode,
  xl2,
  onContinue,
}) => {
  //selected method 1 === verify with email
  //selected method 2 === verify with phone

  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const dropdownRef = useRef(null);

  return (
    <>
      {selectedMethod === 1 && (
        <Header
          xl2={xl2}
          heading="Enter Your email"
          info="We will send you an email with 6 digit verification code to your email."
        />
      )}
      {selectedMethod === 2 && (
        <Header
          xl2={xl2}
          heading="Enter your phone"
          info="We will send you a message with 6 digit verification code to your phone."
        />
      )}
      <div className={classes.inputWrapper}>
        {selectedMethod === 1 && (
          <Input
            value={email}
            setValue={setEmail}
            placeholder="Email address"
          />
        )}
        {selectedMethod === 2 && (
          <div className={classes.phoneNumberContainer}>
            <div ref={dropdownRef}>
              <PhoneNumberDropdown
                isActive={isDropdownActive}
                selectedValue={selectedCountryCode}
                onSelect={(val) => {
                  setSelectedCountryCode(val);

                  setIsDropdownActive(false);
                }}
              >
                <div
                  className={classes.dropdownItem}
                  onClick={() => {
                    setIsDropdownActive((prev) => !prev);
                  }}
                >
                  <span className={classes.code}>
                    {selectedCountryCode.code}
                  </span>
                  {isDropdownActive ? (
                    <FaAngleUp className={classes.arrow} />
                  ) : (
                    <FaAngleDown className={classes.arrow} />
                  )}
                </div>
              </PhoneNumberDropdown>
            </div>
            <Input
              className={classes.input}
              onKeyDown={handleKeyDown}
              type="number"
              placeholder="Phone number"
              value={phone}
              setValue={setPhone}
            />
          </div>
        )}
        <Button
          wFull
          onClick={() => {
            onContinue && onContinue();
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default EnterEmailOrPhone;
