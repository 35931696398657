import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const AntiSpamPolicyPage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>ANTI-SPAM POLICY</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Our Commitment to a Spam-Free Experience
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        At {frontendInfo?.site_name}, we are committed to protecting our users from spam and ensuring a clean, secure, and trustworthy communication environment. This Anti-Spam Policy outlines our stance on spam and the steps we take to prevent it.
                    </Text>
                </div>

                {/* What is Spam Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.whatIsSpam}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            What is Spam?
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Spam refers to unsolicited, bulk, or indiscriminate messages, typically sent for commercial purposes without the recipient's consent. At {frontendInfo?.site_name}, we strictly prohibit the use of our platform to send such messages, as they can negatively affect user experiences and undermine trust in our services.
                        </Text>
                    </div>
                </div>

                {/* Our Anti-Spam Measures Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.antiSpamMeasures}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Our Anti-Spam Measures
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            To maintain a spam-free environment, we take the following measures:
                        </Text>
                        <ul className={classes.measureList}>
                            <li className={classes.measureItem}>
                                <strong>Monitoring and Filtering:</strong> We utilize advanced monitoring and filtering systems to detect and block any suspicious or bulk messaging activity on our platform.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>User Reporting:</strong> Users can report any spam messages they receive. Our support team promptly investigates such reports and takes appropriate action.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Account Suspension:</strong> Accounts found engaging in spam activities will be suspended or terminated immediately to prevent further abuse.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Compliance with Regulations:</strong> We comply with relevant anti-spam laws and regulations to ensure our practices align with industry standards and protect our users.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* User Responsibilities Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.userResponsibilities}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            User Responsibilities
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We encourage our users to help us maintain a spam-free environment by adhering to the following guidelines:
                        </Text>
                        <ul className={classes.responsibilityList}>
                            <li className={classes.responsibilityItem}>
                                <strong>Respect Consent:</strong> Only send messages to individuals who have explicitly agreed to receive communications from you.
                            </li>
                            <li className={classes.responsibilityItem}>
                                <strong>Use Proper Channels:</strong> Do not use {frontendInfo?.site_name}'s services to send bulk or promotional messages without proper authorization.
                            </li>
                            <li className={classes.responsibilityItem}>
                                <strong>Report Spam:</strong> If you receive any unsolicited or suspicious messages, report them to our support team immediately so we can take appropriate action.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Consequences of Violating Policy Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.consequences}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Consequences of Violating Our Anti-Spam Policy
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Violating our Anti-Spam Policy can lead to serious consequences, including:
                        </Text>
                        <ul className={classes.consequenceList}>
                            <li className={classes.consequenceItem}>
                                <strong>Account Suspension or Termination:</strong> Accounts found engaging in spamming activities may be suspended or permanently terminated.
                            </li>
                            <li className={classes.consequenceItem}>
                                <strong>Legal Action:</strong> We reserve the right to take legal action against individuals or entities that engage in activities that violate anti-spam regulations or our policies.
                            </li>
                            <li className={classes.consequenceItem}>
                                <strong>Loss of Access:</strong> Violators may lose access to our services and face permanent bans from using our platform.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AntiSpamPolicyPage;