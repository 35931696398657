import { Button, Heading, Input, Text } from "components/common";
import classes from "./ProvideInformation.module.css";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import clsx from "clsx";

const ProvideInformation = ({ setStep, frontendInfo }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl2 primitive950>
          Provide your information
        </Heading>
        <Text semiBold lg primitive700>
          Please fill the fields below with your contact details.
        </Text>
      </div>

      <div className={classes.allInput}>
        <Input
          label="Your Name"
          type="text"
          value={name}
          setValue={setName}
          placeholder="John Smith"
        />
        <Input
          label="Your Email"
          type="email"
          value={email}
          setValue={setEmail}
          placeholder="johnsmith@example.com"
        />{" "}
        <Input
          label="Number"
          type="tel"
          value={phone}
          setValue={setPhone}
          placeholder="+1 (800) 123-4567"
        />
        <Input
          label="Your Address"
          type="text"
          value={address}
          setValue={setAddress}
          placeholder="Area, City, State, ZIP Code"
        />
      </div>
      <div className={classes.bottomBar}>
        <GoDotFill
          className={clsx(classes.dot, classes.activeDot)}
          onClick={() => setStep(1)}
        />{" "}
        <GoDotFill className={classes.dot} onClick={() => setStep(2)} />
        <Button className={classes.button} onClick={() => setStep(2)}>
          Continue
        </Button>
      </div>
    </section>
  );
};

export default ProvideInformation;
