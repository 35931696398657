import React, { useState, useMemo } from "react";
import Modal from "components/common/Modal/Modal";
import Tabs from "components/common/Tabs/Tabs";
import UserProfile from "components/MyAccount/UserProfile/UserProfile";
import Preferences from "components/MyAccount/Preferences/Preferences";
import Security from "components/MyAccount/Security/Security";
import classes from "./MyAccountModal.module.css";

const MyAccountModal = ({ isActive, onClose, userData }) => {
  const [step, setStep] = useState(0);
  const [selectedVerificationMethod, setSelectedVerificationMethod] =
    useState(0);
  const [activeTab, setActiveTab] = useState("Profile");

  // const tabs = useMemo(() => ["User Profile", "Security", "Preferences"], []);
  const tabs = useMemo(() => ["Profile"], []);
  // const tabs = useMemo(() => ["Profile", "Security"], []);

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case "Profile":
        return <UserProfile userData={userData} />;
      case "Security":
        return (
          <Security
            setStep={setStep}
            step={step}
            selectedVerificationMethod={selectedVerificationMethod}
            setSelectedVerificationMethod={setSelectedVerificationMethod}
          />
        );
      case "Preferences":
        return <Preferences />;
      default:
        return null;
    }
  }, [activeTab, step]); // Memoize to avoid re-rendering when tab changes

  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
        setStep(0);
        setSelectedVerificationMethod(0);
      }}
      heading="My Account"
    >
      <div className={classes.wrapper}>
        {step < 1 && (
          <Tabs
            type2
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {renderActiveTab}
      </div>
    </Modal>
  );
};

export default React.memo(MyAccountModal);
