import React from "react";
import classes from "./About.module.css";
import HeroSection from "components/Landing/About/HeroSection/HeroSection";
import WhoWeAre from "components/Landing/About/WhoWeAre/WhoWeAre";
import OurValues from "components/Landing/About/OurValues/OurValues";
import Investors from "components/Landing/About/Investors/Investors";
import LatestNews from "components/Landing/About/LatestNews/LatestNews";
import ReadyToStart from "components/Landing/ReadyToStart/ReadyToStart";
import { homepage_contents } from "pages/Home/Home";


const About = () => {


  return (
    <main>
      <HeroSection homepage_contents={homepage_contents.about_us} />
      <WhoWeAre homepage_contents={homepage_contents.about_us} />
      <OurValues homepage_contents={homepage_contents.about_us} />
      {/* <Investors homepage_contents={homepage_contents.about_us} /> */}
      {/* <LatestNews /> */}
      <ReadyToStart homepage_contents={homepage_contents.home} />
    </main>
  );
};

export default About;
