import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const AntiMoneyLaunderingPage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>ANTI-MONEY LAUNDERING POLICY</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Our Commitment to Compliance and Security
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        At {frontendInfo?.site_name}, we are dedicated to maintaining the highest standards of compliance and integrity. This Anti-Money Laundering (AML) Policy outlines the steps we take to prevent the use of our platform for illegal activities, including money laundering and terrorist financing.
                    </Text>
                </div>

                {/* What is Money Laundering Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.whatIsMoneyLaundering}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            What is Money Laundering?
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Money laundering is the process of concealing the origins of illegally obtained money, typically by passing it through complex sequences of banking transfers or commercial transactions. The goal is to make the funds appear legitimate. At {frontendInfo?.site_name}, we are committed to identifying and preventing any attempts to use our services for such illegal activities.
                        </Text>
                    </div>
                </div>

                {/* Our AML Measures Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.amlMeasures}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Our Anti-Money Laundering Measures
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            To ensure compliance with AML regulations and to prevent illicit activities, we take the following measures:
                        </Text>
                        <ul className={classes.measureList}>
                            <li className={classes.measureItem}>
                                <strong>Customer Identification Program (CIP):</strong> We verify the identity of all our customers by collecting and validating key personal information, including government-issued identification.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Transaction Monitoring:</strong> We use sophisticated monitoring tools to track and analyze customer transactions for any suspicious activity or patterns that may indicate money laundering.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Risk Assessment:</strong> We conduct comprehensive risk assessments of our customers based on various factors, including transaction history, geographical risk, and activity type, to identify high-risk users.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Record Keeping:</strong> We maintain detailed records of customer transactions and identification documents as required by applicable AML regulations to ensure transparency and traceability.
                            </li>
                            <li className={classes.measureItem}>
                                <strong>Staff Training:</strong> Our staff members receive ongoing training to ensure they understand and are equipped to identify potential money laundering activities and comply with AML regulations.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* User Responsibilities Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.userResponsibilities}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            User Responsibilities
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We encourage our users to contribute to a secure and compliant environment by adhering to the following guidelines:
                        </Text>
                        <ul className={classes.responsibilityList}>
                            <li className={classes.responsibilityItem}>
                                <strong>Provide Accurate Information:</strong> Ensure that all personal information and identification documents provided are accurate, current, and truthful.
                            </li>
                            <li className={classes.responsibilityItem}>
                                <strong>Report Suspicious Activity:</strong> If you become aware of any suspicious activities or transactions, report them to our support team immediately.
                            </li>
                            <li className={classes.responsibilityItem}>
                                <strong>Use Services Responsibly:</strong> Do not use {frontendInfo?.site_name}'s services to engage in any activities that violate AML regulations or other applicable laws.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Consequences of Violating Policy Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.consequences}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Consequences of Violating Our AML Policy
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Violating our Anti-Money Laundering Policy can result in severe consequences, including:
                        </Text>
                        <ul className={classes.consequenceList}>
                            <li className={classes.consequenceItem}>
                                <strong>Account Suspension or Termination:</strong> Accounts involved in activities that violate our AML policies will be suspended or permanently terminated.
                            </li>
                            <li className={classes.consequenceItem}>
                                <strong>Legal Action:</strong> We may take legal action against individuals or entities found to be involved in money laundering or other illegal activities.
                            </li>
                            <li className={classes.consequenceItem}>
                                <strong>Reporting to Authorities:</strong> We are required by law to report any suspicious activities to the appropriate regulatory authorities, which may result in further investigation and legal consequences.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AntiMoneyLaunderingPage;