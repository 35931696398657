import React, { useState } from "react";
import classes from "./Transactions.module.css";
import clsx from "clsx";
import { Text } from "components/common";
import { FaArrowRightLong } from "react-icons/fa6";
import TransactionDetailsModal from "Modals/Dashboard/TransactionDetailsModal/TransactionDetailsModal";

const SingleRow = ({ item }) => {
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);
  return (
    <>
      <tr
        className={clsx(classes.row)}
        onClick={() => setShowTransactionDetailsModal(true)}
      >
        <td className={classes.item}>{
          item.id.split("-")[4]
        }</td>
        {/* <td className={classes.item}>{item.assets}</td> */}
        <td className={classes.item}>{item.created_at}</td>
        <td className={clsx(classes.item, classes.textRight)}>{item.amount} USD</td>
        <td
          className={clsx(
            classes.item,
            classes.textRight,
            classes.type,
            item.transaction_type.toLowerCase() === "deposit"
              ? classes.deposit
              : item.transaction_type.toLowerCase() === "withdraw"
                ? classes.withdraw
                : ""
          )}
        >
          {item.transaction_type}
        </td>
        <td className={classes.item}>
          <div className={classes.statusContainer}>
            <Text
              primitive0
              sm
              font600
              className={clsx(
                classes.status,
                item.status.toLowerCase() === "completed"
                  ? classes.completed
                  : item.status.toLowerCase() === "active"
                    ? classes.active
                    : item.status.toLowerCase() === "pending"
                      ? classes.pending
                      : item.status.toLowerCase() === "failed"
                        ? classes.failed
                        : ""
              )}
            >
              {item.status}
            </Text>
          </div>
        </td>
        <td>
          {item.link && (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <FaArrowRightLong className={classes.arrow} />
            </a>
          )}
        </td>
      </tr>
      <TransactionDetailsModal
        isActive={showTransactionDetailsModal}
        onClose={() => setShowTransactionDetailsModal(false)}
      />
    </>
  );
};

export default SingleRow;
