import React, { useState } from "react";
import { countries } from "common/constants";
import EnterEmailOrPhone from "./EnterEmailOrPhone/EnterEmailOrPhone";
import Verify from "./Verify/Verify";
import VerificationMethod from "./VerificationsMethod/VerificationMethod";
import Buttons from "components/MyAccount/Security/Buttons/Buttons";

const VerifyIdentity = ({
  xl2,
  onVerify,
  step,
  setStep,
  selectedMethod,
  setSelectedMethod,
  type2,
}) => {
  // Verification step constants
  const VERIFICATION_METHOD = 0;
  const ENTER_EMAIL = 1;
  const ENTER_PHONE = 2;
  const ENTER_VERIFICATION_CODE = 3;

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(countries[0]);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);

  // Handles the "Continue" button click
  const handleContinue = () => {
    setIsVerificationCodeSent(true);
    if (type2) setStep((prev) => prev + 1);
  };

  // Handles the verification logic
  const handleVerify = () => {
    if (onVerify) onVerify();
    setIsVerificationCodeSent(false);
  };

  // Handles the cancel and back actions
  const handleCancel = () => {
    setStep(0);
    setSelectedMethod(VERIFICATION_METHOD);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);

    if (step === 2 || selectedMethod === ENTER_VERIFICATION_CODE) {
      setSelectedMethod(VERIFICATION_METHOD);
    }

    if (
      isVerificationCodeSent ||
      selectedMethod === ENTER_EMAIL ||
      selectedMethod === ENTER_PHONE
    ) {
      setIsVerificationCodeSent(false);
    }
  };

  return (
    <>
      {selectedMethod === VERIFICATION_METHOD && (
        <VerificationMethod
          setSelectedMethod={setSelectedMethod}
          selectedMethod={selectedMethod}
          setStep={setStep}
        />
      )}

      {(selectedMethod === ENTER_EMAIL || selectedMethod === ENTER_PHONE) &&
        !isVerificationCodeSent &&
        (!type2 || step === 2) && (
          <EnterEmailOrPhone
            onContinue={handleContinue}
            xl2={xl2}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            selectedMethod={selectedMethod}
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
            countries={countries}
          />
        )}

      {(selectedMethod === ENTER_VERIFICATION_CODE ||
        isVerificationCodeSent ||
        step === 3) && (
        <Verify
          selectedMethod={selectedMethod}
          onVerify={handleVerify}
          xl2={xl2}
          codeSentOn={selectedMethod === ENTER_EMAIL ? email : phone}
        />
      )}

      {type2 && <Buttons handleCancel={handleCancel} handleBack={handleBack} />}
    </>
  );
};

export default VerifyIdentity;
