import React from "react";
import classes from "./Home.module.css";
import HeroSection from "components/Landing/Home/HeroSection/HeroSection";
import AboutUs from "components/Landing/Home/AboutUs/AboutUs";
import HowToStart from "components/Landing/Home/HowToStart/HowToStart";

import OurSpecialties from "components/Landing/Home/OurSpecialties/OurSpecialties";
import WhatInvestorsSay from "components/Landing/Home/WhatInvestorsSay/WhatInvestorsSay";
import ReadyToStart from "components/Landing/ReadyToStart/ReadyToStart";
import Footer from "Layout/Footer/Footer";
import FlexibleDepositPlan from "components/Landing/Home/FlexibleDepositPlan/FlexibleDepositPlan";
import {
  chooseAccIcon,
  choosePlan,
  depositFund,
  marketOverviewImg,
} from "images";
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  quotationIcon,
} from "images";
import { FaDAndDBeyond } from "react-icons/fa6";

export const homepage_contents = {
  home: {
    hero: {
      heading: "Grow Your Wealth with Confidence",
      title: "Invest in the future of finance",
      subtitle: "Join thousands of successful investors. Start earning today with our secure, user-friendly, and effective platform",
      first_button: "Join Now",
      second_button: "Learn More"
    },
    about_us: {
      heading: "About Us",
      title: "Invest in the future of finance",
      subtitle: `We are a leading investment platform committed to helping you grow
            your wealth. Our mission is to provide a secure and user-friendly
            environment where you can achieve your financial goals. With years
            of industry experience, we offer expert management, cutting-edge
            technology, and a variety of investment opportunities tailored to
            your needs.`,
      first_button: "Join Now",
      second_button: "Learn More"
    },
    how_to_start: {
      header: "How To Start",
      title: "Simple Steps to Begin Your Investment Journey",
      mini_header: "Get Your Interest and Enjoy Your Investment",
      mini_subtitle: "Our platform is designed for easy navigation and seamless user experience. Follow these simple steps to begin your investment journey.",
      steps: [
        {
          icon: chooseAccIcon,
          title: "Create an Account",
          info: "Our quick registration process gets you started in minutes. Simply provide your details, and you're ready to explore our investment opportunities.",
        },
        {
          icon: depositFund,
          title: "Deposit Your Fund",
          info: "Securely deposit your funds into your account. We offer a variety of payment options, ensuring that your investments are safe and easily accessible.",
        },
        {
          icon: choosePlan,
          title: "Choose Plan",
          info: "Select a membership plan that aligns with your investment goals. Whether you're just starting or an experienced investor, we have a plan that fits your needs.",
        },

      ]
    },
    flexible_plans: {
      title: "FLEXIBLE DEPOSIT PLAN",
      heading: "Choose Your Deposit Plan And Start your Investments",
      subtitle: "Whether you're a seasoned investor or just starting, our flexible plans are designed to help you grow your wealth. Choose a plan that aligns with your financial goals and start earning today.",
    },

    our_specialties: {
      heading: "WHY INVESTORS TRUST US",
      title: "Our Specialties",
      subtitle: "Our platform stands out in the market due to our unwavering commitment to transparency, security, and high returns.",

      features: [
        {
          title: "Intuitive Platform",
          info: "Experience seamless navigation and management of your investments with our user-friendly interface.",
        },
        {
          title: "Around-the-Clock Support",
          info: "Our dedicated support team is available 24/7 to assist you with any questions or concerns.",
        },
        {
          title: "Live Investment Tracking",
          info: "Keep a close eye on your portfolio with real-time updates and performance insights at your fingertips.",
        },
      ]
    },
    testimonials: {
      heading: "TESTIMONIALS",
      title: "What Investors Say",
      subtitle: "Take the first step towards financial success with our platform designed for investors of all levels.",
      user_testimonials: [
        {
          author: "Jane Doe",
          img: avatar1,
          userName: "@sarahmitchel",
          info: "Investing with [Platform Name] has been a game-changer for me. The platform is secure, and the returns have exceeded my expectations.",
        },
        {
          author: "Carlos Ron",
          img: avatar2,
          userName: "@sarahmitchel",
          info: "I was new to investing, but [Platform Name] made it easy to get started and it is game-changer. The support team is fantastic!",
        },
        {
          author: "Emily Willie",
          img: avatar3,
          userName: "@sarahmitchel",
          info: "The flexibility of the deposit plans and the transparency in transactions is what I love the most. The support team is awesome.",
        },
        {
          author: "Sarah Mitchel",
          img: avatar4,
          userName: "@sarahmitchel",
          info: "Investing with [Platform Name] has been a game-changer for me. The platform is secure, and the returns have exceeded my expectations.",
        },
        {
          author: "Sarah Mitchel",
          img: avatar5,
          userName: "@sarahmitchel",
          info: "I was new to investing, but [Platform Name] made it easy to get started and it is game-changer. The support team is fantastic!",
        },
        {
          author: "Sarah Mitchel",
          img: avatar5,
          userName: "@sarahmitchel",
          info: "I was new to investing, but [Platform Name] made it easy to get started and it is game-changer. The support team is fantastic!",
        },
        {
          author: "Sarah Mitchel",
          img: avatar5,
          userName: "@sarahmitchel",
          info: "I was new to investing, but [Platform Name] made it easy to get started and it is game-changer. The support team is fantastic!",
        },
      ]
    },
    ready_to_start: {
      heading: "Ready to Start Investing?",
      title: "Join thousands of investors who have grown their wealth with us.",
      subtitle: "Get started today and start earning with our secure and user-friendly platform.",
      first_button: "Join Now",
      second_button: "Learn More",
    }
  },
  about_us: {
    hero: {
      title: "About Us",
      heading: "Invest in the future of finance",
    },
    who_we_are: {
      title: "Who We Are",
      heading: "Invest with Confidence: A Clear Path to Financial Freedom",
      subtitle: `We are a leading investment platform committed to helping you grow
            your wealth. Our mission is to provide a secure and user-friendly
            environment where you can achieve your financial goals. With years
            of industry experience, we offer expert management, cutting-edge
            technology, and a variety of investment opportunities tailored to
            your needs.`,
    },
    our_values: {
      title: "Our Values",
      heading: "Start Small, Grow Big: Your Investment Journey Begins Here",
      subtitle: `Our platform is designed to be a trusted partner on your
            investment journey, offering transparency, flexibility, and
            reliability.`,
      facilities: [
        {
          title: "Integrity",
          info: "We operate with the highest ethical standards, ensuring honesty and fairness in all our dealings.",
        },
        {
          title: "Customer-Centricity",
          info: "Your success is our success. We are committed to putting your needs first and delivering exceptional value at every step.",
        },
        {
          title: "Innovation",
          info: "We embrace change and continuously seek new ways to enhance your investment experience.",
        },
      ]
    }
  }

}
const Home = ({ frontendInfo, isLoggedIn }) => {

  return (
    <main>
      <HeroSection homepage_contents={homepage_contents.home} frontendInfo={frontendInfo} isLoggedIn={isLoggedIn} />
      <AboutUs homepage_contents={homepage_contents.home} />
      <HowToStart homepage_contents={homepage_contents.home} />
      <FlexibleDepositPlan homepage_contents={homepage_contents.home} />
      <OurSpecialties homepage_contents={homepage_contents.home} />
      {/* <WhatInvestorsSay homepage_contents={homepage_contents.home} /> */}
      <ReadyToStart homepage_contents={homepage_contents.home} />
    </main >
  );
};

export default Home;
