import React, { useEffect, useState } from "react";
import classes from "./Transactions.module.css";
import clsx from "clsx";
import { Heading, Text } from "components/common";
import Tabs from "components/common/Tabs/Tabs";
import SingleRow from "./SingleRow";
import { get_transactions } from "helpers/api/apis";

const Transactions = () => {
  const [activeTab, setActiveTab] = useState("All History");
  const tabs = ["All History", "Investment", "Deposit", "Withdraw",];
  const [transactions, setTransactions] = useState([]);

  async function get_tx() {
    let { data } = await get_transactions();
    setTransactions(data);
  }

  useEffect(() => {
    get_tx();
  }, []);

  const filteredData =
    activeTab === "Deposit"
      ? transactions?.filter((item) => item.transaction_type.toLowerCase() === "deposit")
      : activeTab === "Withdraw"
        ? transactions?.filter((item) => item.transaction_type.toLowerCase() === "withdraw")
        : transactions;

  return (
    <main className={clsx(classes.wrapper)}>
      <div className={classes.header}>
        <Heading semiBold xl primitive950>
          Transactions
        </Heading>
        <Text base primitive600>
          Total: {filteredData.length}
        </Text>
      </div>
      <Tabs
        className={classes.tabs}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={clsx(classes.tableContainer, "overflow")}>
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              <th className={classes.heading}>Trx. ID</th>
              {/* <th className={classes.heading}>Assets</th> */}
              <th className={classes.heading}>Date</th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Amount
              </th>
              <th className={clsx(classes.heading, classes.textRight)}>Type</th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Status
              </th>
              <th className={classes.heading}></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <SingleRow item={item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default Transactions;
