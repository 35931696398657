import React, { useState } from "react";
import classes from "./Form.module.css";
import { Button } from "components/common";
import ChangePassword from "../ChangePassword/ChangePassword";
import PasswordChanged from "../PasswordChanged/PasswordChanged";
import VerifyIdentity from "../../VerifyIdentity/VerifyIdentity";

const Form = ({ step, setStep }) => {
  // step === 1 verification method
  //step ===2 verify identity
  //step === 3 change password
  // step === 4 password change succesfull

  // methodNumber =0 show verification method screen

  const [selectedMethod, setSelectedMethod] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  return (
    <div className={classes.wrapper}>
      {(step === 1 || step === 2) && (
        <VerifyIdentity
          onVerify={() => setStep((prev) => prev + 1)}
          setStep={setStep}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
        />
      )}
      {step === 3 && (
        <ChangePassword
          setStep={setStep}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          onSavePassword={() => {
            setStep((prev) => prev + 1);
          }}
        />
      )}
      {step === 4 && (
        <PasswordChanged
          setStep={setStep}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
        />
      )}

      {step > 4 && (
        <Button transparent className={classes.button} to="/login">
          Return to Login
        </Button>
      )}
    </div>
  );
};

export default Form;
