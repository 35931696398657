import React from "react";
import Wrapper from "components/common/Wrapper/Wrapper";
import classes from "./InfoContainer.module.css";
import { Heading, Text } from "components/common";
import { Link } from "react-router-dom";
import { instantAccessIcon, secureLoginIcon, support24 } from "images";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCreative } from "swiper/modules"; // Import necessary modules
import clsx from "clsx";

// No need to manually install modules when using `modules` prop in the Swiper component

const InfoContainer = () => {
  const data = [
    {
      icon: secureLoginIcon,
      title: "Secure Login",
      info: "Advanced encryption and two-factor authentication to protect your information.",
    },
    {
      icon: instantAccessIcon,
      title: "Instant Access",
      info: "Quick login process ensures you’re just a click away from your personalized dashboard.",
    },
    {
      icon: support24,
      title: "24/7 Support",
      info: "Our dedicated team is here to assist with any login issues or account inquiries.",
    },
  ];

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.content}>
        <Heading primitive0 xl4>
          Welcome Back!
        </Heading>
        <Text xl primitive200 semiBold className={classes.info}>
          Access Your Account with Ease. Secure and Simple Login for a
          Personalized Experience.
        </Text>
        <Text lg primitive300 className={classes.helpContainer}>
          Need Help?
          <Link className={classes.link} to="/contact">
            Contact
          </Link>
        </Text>

        <div className={classes.cards}>
          <Swiper
            loop={true}
            effect="fade" // Apply the fade effect
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay, EffectCreative]}
            scrollbar={{ hide: true }}
            className={clsx("collections-swiper", classes.swiper)}
          >
            {data.map((el, index) => (
              <SwiperSlide key={index}>
                <div className={classes.card}>
                  <img src={el.icon} alt={el.title} className={classes.icon} />
                  <div className={classes.titleAndInfo}>
                    <Heading xl primitive900 semiBold className={classes.title}>
                      {el.title}
                    </Heading>
                    <Text base primitive700 className={classes.info}>
                      {el.info}
                    </Text>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
};

export default InfoContainer;
