import React from "react";
import classes from "./MyPlan.module.css";
import { Heading, Text } from "components/common";
import clsx from "clsx";
import { get_earnings } from "helpers/api/apis";
const MyPlan = ({ el, plan }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading base semiBold>
          My Plan
        </Heading>
        <Text className={classes.plan} primitive0 sm uppercase>
          {plan?.plan_id}
        </Text>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive600 base>
            Capital
          </Text>
          <Text
            className={clsx(classes.value, classes.active)}
            primitive950
            base
            semiBold
          >
            {plan?.capital} USD
          </Text>
        </div>

        {/*  */}
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive600 base>
            Start Date
          </Text>
          <Text
            className={clsx(
              classes.value,
              el?.status.toLowerCase() === "s"
                ? classes.completed
                : el?.status.toLowerCase() === "s"
                ? classes.pending
                : el?.status.toLowerCase() === "s"
                ? classes.active
                : el?.status.toLowerCase() === "s"
                ? classes.failed
                : ""
            )}
            primitive950
            base
            semiBold
          >
            {new Date(plan?.created_at_timestamp * 1000).toLocaleDateString()}
          </Text>
        </div>

        {/*  */}

        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive600 base>
            End Date
          </Text>
          <Text
            className={clsx(
              classes.value,
              el?.status.toLowerCase() === "s"
                ? classes.completed
                : el?.status.toLowerCase() === "s"
                ? classes.pending
                : el?.status.toLowerCase() === "s"
                ? classes.active
                : el?.status.toLowerCase() === "s"
                ? classes.failed
                : ""
            )}
            primitive950
            base
            semiBold
          >
            {new Date(plan?.end_date_timestamp * 1000).toLocaleDateString()}
          </Text>
        </div>

        {/*  */}
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive600 base>
            Withdrawn Profits
          </Text>
          <Text
            className={clsx(
              classes.value
              // el?.status.toLowerCase() === "s"
              //   ? classes.completed
              //   : el?.status.toLowerCase() === "s"
              //     ? classes.pending
              //     : el?.status.toLowerCase() === "s"
              //       ?
              //       : el?.status.toLowerCase() === "s"
              //         ? classes.failed
              //         : ""
              // classes.pending
            )}
            primitive950
            base
            semiBold
          >
            {plan?.total_profits_withdrawn} USD
          </Text>
        </div>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive600 base>
            Withdrawn Profits
          </Text>
          <Text
            className={clsx(
              classes.value,
              plan?.status.toLowerCase() === "completed"
                ? classes.completed
                : plan?.status.toLowerCase() === "pending"
                ? classes.pending
                : plan?.status.toLowerCase() === "failed"
                ? classes.failed
                : plan?.status.toLowerCase() === "active"
                ? classes.active
                : ""
            )}
            primitive950
            base
            semiBold
          >
            {plan?.status}
          </Text>
        </div>

        {/*  */}
      </div>
    </div>
  );
};

export default MyPlan;
