import React from "react";
import classes from "./InvesmentsDetailsModal.module.css";
import Modal from "components/common/Modal/Modal";

import MyPlan from "components/Dashboard/ActiveInvestments/MyPlan/MyPlan";
import Chart from "components/Dashboard/ActiveInvestments/Chart/Chart";

const InvesmentsDetailsModal = ({
  item,
  isActive,
  onClose,
  setShowWithdrawModal,
}) => {
  const data = [
    { key: "ID", value: "A123456789" },
    { key: "Start in", value: "2 Sep 2024" },
    { key: "Expire in", value: "30 Sep 2024" },
    { key: "Duration", value: "Active" },
  ];
  return (
    <>
      <Modal
        isActive={isActive}
        onClose={onClose}
        heading="Investments Details"
      >
        <div className={classes.wrapper}>
          <MyPlan plan={item} />
          <p className={classes.border}></p>

          <Chart
            el={item}
            type2
            onClose={onClose}
            setShowWithdrawModal={setShowWithdrawModal}
          />
        </div>
      </Modal>
    </>
  );
};

export default InvesmentsDetailsModal;
