import clsx from "clsx";
import React from "react";
import classes from "./Security.module.css";
import { Button, Text } from "components/common";
import { MdClose } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const SignInInfo = ({ label, logo, value, onClick }) => {
  return (
    <div className={clsx(classes.infoBox)}>
      <Text sm primitive500>
        {label}
      </Text>
      <Text textLeft font600 primitive500 className={classes.logoContainer}>
        {logo}
      </Text>
      <Button
        transparent
        className={clsx(value ? classes.removeButton : classes.addButton)}
        onClick={onClick}
      >
        {value ? (
          <MdClose className={classes.icon} />
        ) : (
          <FaPlus className={classes.icon} />
        )}
      </Button>
    </div>
  );
};

export default SignInInfo;
