import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";
import { Button, Heading } from "components/common";

const HeroSection = ({ homepage_contents }) => {
  let { hero } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <Heading xl4 primitive950>
              {hero.title}
            </Heading>
            <Heading xl6 primitive950>
              {hero.heading}
            </Heading>
          </div>{" "}
          <Button transparent className={classes.button}>
            JOIN NOW
          </Button>
        </div>{" "}
        {/* <img src={aboutHeroImg} alt="#" className={classes.img} /> */}
      </div>
    </section>
  );
};

export default HeroSection;
