import React from "react";
import classes from "./GenerateAddressModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Text } from "components/common";
import { LuCopy } from "react-icons/lu";

import QRCode from "react-qr-code";
import { copyToClipboard } from "utils/utils";

const GenerateAddressModal = ({
  isActive,
  paymentDetails,
  onClose,
  onClick,
}) => {
  return (
    <>
      <Modal isActive={isActive} onClose={onClose} heading="Deposit">
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Text primitive600 base>
              <span className={classes.note}>Note:</span>When you send your
              crypto, please ensure that your network and wallet address is
              correct. Otherwise you can lose your assets.
            </Text>
          </div>
          <div className={classes.qrCodeWrapper}>
            <div className={classes.qrCode}>
              <QRCode
                size={112}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                  borderRadius: "6px",
                }}
                value={""}
                viewBox={`0 0 256 256`}
              />
            </div>
            <Text base primitive800>
              {/* Share the USDT wallet address to the sender to receive the
              currency. */}

              {/* {
                "Share the " +
                paymentDetails?.pay_currency +
                " wallet address to the sender to receive the currency."
              } */}
              {/* i need to include the amount */}
              {/* {`
                Share the ${paymentDetails?.pay_currency} wallet address to the sender to receive the ${paymentDetails?.pay_amount} .
              `} */}

              {`Share the ${paymentDetails?.pay_currency} wallet address to the sender to receive the ${paymentDetails?.pay_amount} ${paymentDetails?.pay_currency}.`}
            </Text>
            <div className={classes.addressContainer}>
              <Text primitive700 base semiBold className={classes.address}>
                {paymentDetails?.pay_address}
              </Text>
              <button
                className={classes.copyButton}
                onClick={() =>
                  copyToClipboard(paymentDetails?.pay_address)
                }
              >
                <LuCopy />
              </button>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button transparent onClick={onClose} className={classes.button}>
              Close
            </Button>{" "}
            <Button
              loading={true}
              onClick={() => {
                onClose();
                onClick();
              }}
            >
              Waiting for deposit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerateAddressModal;
