import React from "react";
import classes from "./MainLayout.module.css";
import Footer from "Layout/Footer/Footer";
import CopyrightContainer from "Layout/CopyrightContainer/CopyrightContainer";
import { Outlet } from "react-router-dom";
import Navbar from "Layout/Navbar/Navbar";

const MainLayout = ({ isLoggedIn, frontendInfo }) => {
  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <main className={classes.wrapper}>
        <div className={classes.outlet}>
          <Outlet />
        </div>
        <Footer frontendInfo={frontendInfo} />
        <CopyrightContainer frontendInfo={frontendInfo} />
      </main>
    </>
  );
};

export default MainLayout;
