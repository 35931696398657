import React from "react";
import classes from "./Investors.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";
import {
  behanceLogo,
  mailchimpLogo,
  stumbleuponLogo,
  techcrunchLogo,
  treehouseLogo,
  vkLogo,
} from "images";
import Marquee from "react-fast-marquee";

const Investors = () => {
  const investors = [
    { logo: behanceLogo, name: "Behance", link: "#" },
    { logo: techcrunchLogo, name: "TechCrunch", link: "#" },
    { logo: mailchimpLogo, name: "Mailchimp", link: "#" },
    { logo: stumbleuponLogo, name: "Stumbleupon", link: "#" },
    { logo: vkLogo, name: "VK", link: "#" },
    { logo: treehouseLogo, name: "TreeHouse", link: "#" },
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <SectionHeading>INVESTORS</SectionHeading>
          <Heading xl5 primitive950 className={classes.heading}>
            Meet our <span className="highlight">top—tier</span> investors
          </Heading>
          <Text primitive800 xl2 semiBold>
            Our mission is to make investing accessible to everyone with easy
            and simple way.
          </Text>
        </div>
        <div className={classes.investorContainer}>
          <Marquee autoFill pauseOnHover>
            {investors?.map((investor, i) => (
              <a
                href={investor.link}
                target="_blank"
                rel="noreferrer"
                className={classes.investor}
                key={i}
              >
                <img
                  src={investor.logo}
                  alt={investor.name}
                  className={classes.investorLogo}
                />
                <Text
                  className={classes.investorName}
                  base
                  primitive800
                  semiBold
                >
                  {investor.name}
                </Text>
              </a>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default Investors;
