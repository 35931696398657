import React from "react";
import classes from "./WhoWeAre.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";
import { whoWrAreImg } from "images";

const WhoWeAre = ({ homepage_contents }) => {
  let { who_we_are } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <SectionHeading>{who_we_are?.title}</SectionHeading>
          <Heading xl5 className={classes.heading}>
            {who_we_are?.heading}
          </Heading>
        </div>
        <div className={classes.hr} />
        <div className={classes.content}>
          <Text xl2 primitive950 className={classes.info}>
            {who_we_are?.subtitle}
          </Text>

          <div className={classes.imgContainer}>
            <img src={whoWrAreImg} alt="#" className={classes.img} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
