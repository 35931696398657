import React from "react";
import classes from "./Footer.module.css";
import clsx from "clsx";
import { Text } from "components/common";
import { TbBrandTelegram } from "react-icons/tb";

import {
  RiDiscordLine,
  RiTwitterXFill,
  RiFacebookBoxLine,
  RiTiktokLine,
  RiInstagramLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { smallLogo } from "images";
import CopyrightContainer from "Layout/CopyrightContainer/CopyrightContainer";

const Footer = ({ frontendInfo }) => {
  const socialMedia = [
    // {
    //   icon: <RiDiscordLine className={classes.icon} />,

    //   to: "#",
    // },
    // {
    //   icon: <RiTwitterXFill className={classes.icon} />,

    //   to: "#",
    // },
    // {
    //   icon: <TbBrandTelegram className={classes.icon} />,

    //   to: "#",
    // },
    // {
    //   icon: <RiTiktokLine className={classes.icon} />,

    //   to: "#",
    // },
    // {
    //   icon: <RiFacebookBoxLine className={classes.icon} />,

    //   to: "#",
    // },
    // {
    //   icon: <RiInstagramLine className={classes.icon} />,

    //   to: "#",
    // },
  ];
  return (
    <section className={classes.wrapper}>
      <footer className={clsx(classes.container, "container")}>
        <div className={classes.infoContainer}>
          <Text primitive300 xl>
            Whether you're a seasoned investor or just starting, our flexible
            deposit plans are designed to help you grow your wealth.
          </Text>

          <div className={classes.socialContainer}>
            {socialMedia.map((el, i) => (
              <a
                href={el.to}
                target="_blank"
                rel="noreferrer"
                key={i}
                className={classes.social}
              >
                {el.icon}
              </a>
            ))}
          </div>
        </div>
        <div className={classes.itemsContainer}>
          <Link to="/about" className={classes.link}>
            About Us
          </Link>
          <Link to="/how-to-start" className={classes.link}>
            How To Start
          </Link>
          <Link to="/sign-up" className={classes.link}>
            Sign up
          </Link>{" "}
          <Link to="/contact" className={classes.link}>
            Contact
          </Link>
        </div>
        <div className={classes.itemsContainer}>
          <a
            href="risk-disclosure"
            // target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Risk Disclosure
          </a>
          <a
            href="privacy-policy"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Privacy Policy
          </a>
          <a
            href="terms-of-use"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Terms and Conditions
          </a>
          <a
            href="anti-money-laundering"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Anti Money Laundering
          </a>{" "}
          {/* <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Placement of Statemen
          </a> */}
        </div>
        <div className={clsx(classes.infoContainer, classes.addressContainer)}>
          <div>
            <a
              href={`tel:${frontendInfo?.phone_number}`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {frontendInfo?.phone_number}
            </a>
            <br></br>
            <a
              href={`mailto:${frontendInfo?.email}`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {frontendInfo?.email}{" "}
            </a>
          </div>
          <p className={classes.link}>
            {frontendInfo?.address}

          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
