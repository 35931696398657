import { Button, Dropdown, Heading, Input, Text } from "components/common";
import classes from "./LeaveMessage.module.css";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import clsx from "clsx";
const dropdownItems = [
  "enquiry about your process?",
  "enquiry about your process2?",
  "enquiry about your process3?",
];
const LeaveMessage = ({ setStep }) => {
  const [subject, setSubject] = useState("");

  const [message, setMessage] = useState("");
  const [selectedWant, setSelectedWant] = useState(
    "enquiry about your process?"
  );
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl2 primitive950>
          Leave your message
        </Heading>
        <Text semiBold lg primitive700>
          Please enter the message details in the fields below.
        </Text>
      </div>

      <div className={classes.allInput}>
        <Input
          label="Subject"
          type="text"
          value={subject}
          setValue={setSubject}
          placeholder="General Enquiry"
        />
        <div className={classes.dropdownContainer}>
          <p className={classes.label}>I want to</p>
          <Dropdown
            items={dropdownItems}
            isActive={showDropdown}
            setIsActive={setShowDropdown}
            selectedValue={selectedWant}
            setSelectedValue={setSelectedWant}
            onSelect={(val) => setSelectedWant(val)}
          />
        </div>

        <div className={classes.messageContainer}>
          <label htmlFor="message" className={classes.label}>
            Message
          </label>
          <textarea
            className={classes.message}
            rows={4}
            name="message"
            id="message"
            placeholder="write your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className={classes.bottomBar}>
        <GoDotFill
          className={clsx(classes.dot, classes.activeDot)}
          onClick={() => setStep(1)}
        />{" "}
        <GoDotFill
          className={clsx(classes.dot, classes.activeDot)}
          onClick={() => setStep(2)}
        />
        <Button
          className={classes.button}
          onClick={() => setStep((prev) => prev + 1)}
        >
          Send Message
        </Button>
      </div>
    </section>
  );
};

export default LeaveMessage;
