import React, { useState } from "react";
import classes from "./SignupForm.module.css";

import { Link } from "react-router-dom";
import { Button, Heading, Input, Text } from "components/common";

import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import clsx from "clsx";
import { register_user } from "helpers/api/apis";
import { toast } from "react-toastify";

const SignupForm = ({ setStep, frontendInfo }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_name, setUserName] = useState("");
  const [referrer, setReferrer] = useState("");


  const [confirmPassword, setConfirmPassword] = useState("");

  async function submit_signup(params) {


    if (password != confirmPassword) {
      toast.warn("Password and confirm password do not match");
      return;
    }


    if (password.length < 8) {
      toast.warn("Password must be at least 8 characters");
      return;
    }

    if (first_name.length < 1) {
      toast.warn("First name is required");
      return;
    }

    if (last_name.length < 1) {
      toast.warn("Last name is required");
      return;
    }

    if (user_name.length < 1) {
      toast.warn("User name is required");
      return;
    }
    let body = {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      user_name: user_name,
      referrer: referrer,
    };

    let { data, status } = await register_user(body);
    if (status != 200) {
      return;
    } else {
      setStep(2);
    }
  }
  // email: String,
  // password: String,
  // first_name: Option<String>,
  // last_name: Option<String>,
  // user_name: Option<String>,
  // referrer: Option<String>,
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <Heading xl3 primitive950 semiBold textCenter>
          Sign up
        </Heading>
        <Text lg primitive600 textCenter className={classes.allReadyHave}>
          Already have an account?
          <Link className={classes.link} to="/login">
            Login
          </Link>
        </Text>
      </div>

      <div className={classes.inputWrapper}>
        <Input
          type="email"
          value={email}
          setValue={setEmail}
          placeholder="Email address"
        />
        <Input type="text" value={first_name} setValue={setFirstName} placeholder="First name" />
        <Input type="text" value={last_name} setValue={setLastName} placeholder="Last name" />
        <Input type="text" value={user_name} setValue={setUserName} placeholder="Username" />
        <Input type="text" value={referrer} setValue={setReferrer} placeholder="Referrer" />

        <Input
          type="password"
          value={password}
          setValue={setPassword}
          placeholder="Password"
        />
        <Input
          type="password"
          value={confirmPassword}
          setValue={setConfirmPassword}
          placeholder="Confirm Password"
        />
        <Button base wFull onClick={() => submit_signup()}>
          {" "}
          Create Account
        </Button>
      </div>
      {/* <Text primitive600 sm className={classes.or} textCenter>
        or
      </Text> */}
      {/* <div className={classes.buttonContainer}>
        <Button base primitive50 onClick={() => setStep(2)}>
          <FcGoogle className={classes.logo} /> Use Google
        </Button>{" "}
        <Button base primitive50 onClick={() => setStep(2)}>
          <FaApple className={clsx(classes.logo, classes.appleLogo)} /> Use
          Apple
        </Button>
      </div> */}
    </div>
  );
};

export default SignupForm;
