import React, { useState } from "react";
import classes from "./MarketsOverview.module.css";
import { Heading, Text } from "components/common";
import Dropdown from "./Dropdown/Dropdown";
import { bitcoinLogo } from "images";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import clsx from "clsx";
import MarketTable from "./MarketTable/MarketTable";
import TradingView from "components/MyAccount/UserProfile/TradingView";

const MarketsOverview = () => {
  return (
    <div className={classes.myTradingView}>
      <TradingView />
    </div>
  );
};

export default MarketsOverview;
