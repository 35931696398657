import React, { useState } from "react";
import classes from "./ActiveInvestment.module.css";
import MyPlan from "./MyPlan/MyPlan";
import Chart from "./Chart/Chart";
import { Button, Heading } from "components/common";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
const ActiveInvestment = ({ positions }) => {
  let active_positions = positions.filter(
    (el) => el.status.toLowerCase() === "active"
  );

  const navigate = useNavigate();
  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg semiBold>
          Active Investments ({active_positions?.length})
        </Heading>
        <Button
          onClick={
            () => {
              navigate("/my-investments")
            }
          }
          transparent base className={classes.button}>
          {" "}
          See All
        </Button>
      </div>
      <div className={clsx(classes.container, "overflow")}>
        {active_positions?.map((el, i) => (
          <div className={classes.cards} key={i}>
            <MyPlan plan={el} />
            <p className={classes.border}></p>
            <Chart el={el} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ActiveInvestment;
