import React from "react";
import classes from "./Login.module.css";
import clsx from "clsx";
import InfoContainer from "../../../components/Athentication/Login/InfoContainer/InfoContainer";
import LoginForm from "components/Athentication/Login/LoginForm/LoginForm";

const Login = ({ setIsLoggedIn, setReloadState }) => {
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <InfoContainer />
        <LoginForm setIsLoggedIn={setIsLoggedIn} setReloadState={setReloadState} />
      </div>
    </section>
  );
};

export default Login;
