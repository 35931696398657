import React from "react";
import classes from "./InvestmentDetails.module.css";
import InvestmentChart from "./InvestmentChart/InvestmentChart";
import { Counter, Heading, Text } from "components/common";
import clsx from "clsx";

const InvestmentDetails = ({ positions }) => {
  console.log("positions", positions);
  let total_capital = positions?.reduce((acc, curr) => acc + parseInt(curr.capital), 0);

  let capital_where_status_is_active = positions?.filter((position) => position.status === "Active").reduce((acc, curr) => acc + parseInt(curr.capital), 0);





  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.counter)}>

        <div className={classes.infoBox}>
          <Text sm semiBold primitive700 textCenter>
            Total Investments
          </Text>
          <Heading lg textCenter semiBold primitive950>
            <Counter start={0} end={total_capital ? parseInt(total_capital) : 0} />{" "}
            <span className={classes.currency}>USD</span>
          </Heading>
        </div>
        <div className={classes.infoBox}>
          <Text sm semiBold primitive700 textCenter>
            Current Investments
          </Text>
          <Heading lg textCenter semiBold primitive950>
            <Counter start={0} end={capital_where_status_is_active ? parseInt(capital_where_status_is_active) : 0} />{" "}
            <span className={classes.currency}>USD</span>
          </Heading>
        </div>

        <div className={classes.infoBox}>
          <Text sm semiBold primitive700 textCenter>
            Total Positions
          </Text>
          <Heading lg textCenter semiBold primitive950>
            <Counter start={0} end={parseInt(positions?.length)} />{" "}
            <span className={classes.currency}></span>
          </Heading>
        </div>
      </div>
      <p className={classes.hr} />
      <InvestmentChart positions={positions} />
    </section>
  );
};

export default InvestmentDetails;
