import React, { useEffect, useState } from "react";
import classes from "./MyInvestments.module.css";
import clsx from "clsx";
import { Heading, Text } from "components/common";
import Tabs from "components/common/Tabs/Tabs";

import SingleRow from "./SingleRow";
import { get_positions } from "helpers/api/apis";

const MyInvestments = ({ positions, plans }) => {
  const [activeTab, setActiveTab] = useState("All History");
  const tabs = ["All History", "Active", "Completed"];
  const filteredData =
    activeTab === "Active"
      ? positions.filter((item) => item.status.toLowerCase() === "active")
      : activeTab === "Completed"
        ? positions.filter((item) => item.status.toLowerCase() === "completed")
        : positions;


  return (
    <main className={clsx(classes.wrapper)}>
      <div className={classes.header}>
        <Heading semiBold xl primitive950>
          Investments List
        </Heading>
        <Text base primitive600>
          Total: {filteredData.length}
        </Text>
      </div>
      <Tabs
        className={classes.tabs}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={clsx(classes.tableContainer, "overflow")}>
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              <th className={classes.heading}>Id</th>
              <th className={classes.heading}>Plan</th>
              <th className={classes.heading}>Date</th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Investment Amount
              </th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Reward Schedule
              </th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Unclaimed Earnings
              </th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Status
              </th>
              <th className={classes.heading}></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <SingleRow key={index} item={item} plans={plans} />
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default MyInvestments;
