import Wrapper from "components/common/Wrapper/Wrapper";

import React from "react";
import classes from "./InfoContainer.module.css";
import { Heading, Text } from "components/common";
import { Link } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import clsx from "clsx";
const InfoContainer = ({ step }) => {
  const steps = [
    "Select Verification Method",
    "Verify your identity",
    "Change your password",
  ];

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Heading primitive0 xl4>
            Forgot Password?
          </Heading>
          <Text xl primitive200 semiBold className={classes.info}>
            Don’t worry, we’ll help you securely reset your password in just a
            few steps.
          </Text>
        </div>

        <div className={classes.steps}>
          {steps.map((el, i) => (
            <div
              className={clsx(
                classes.step,
                step === i + 1 && classes.semiActive,
                step > i + 1 && classes.active
              )}
              key={i}
            >
              {step > i + 1 ? (
                <FaCheckCircle className={classes.icon} />
              ) : (
                <FaRegCheckCircle className={classes.icon} />
              )}
              <Text primitive300 xl className={classes.stepInfo}>
                {el}
              </Text>
            </div>
          ))}
        </div>
        <Text lg primitive300 className={classes.helpContainer}>
          Need Help?
          <Link className={classes.link} to="/contact">
            Contact
          </Link>
        </Text>
      </div>
    </Wrapper>
  );
};

export default InfoContainer;
