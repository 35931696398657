import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const PrivacyPolicyPage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>PRIVACY POLICY</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Last Updated on June 4, 2024
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING AND USING THIS WEBSITE OR MOBILE APPLICATION, AS APPLICABLE, YOU AGREE TO BE BOUND BY THIS PRIVACY POLICY IN ITS ENTIRETY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE OUR SITE(S).
                    </Text>
                    <Text primitive800 xl className={classes.info}>
                        Each of our website, our mobile application, and any related URLs (each and collectively, as appropriate, the “Site”) is brought to you by {frontendInfo?.site_name} (“we,” “us,” or “our”). This Privacy Policy provides information about our policies and procedures regarding the collection, use, and disclosure of personal information received through the Site from visitors and registered users of the Site (the “Privacy Policy”). This Privacy Policy is incorporated into, and is part of, our Terms of Use which govern your access to the Site and/or your use of the Site and of the Content (as defined in the Terms of Use) and the services made available on or through the Site (collectively, the “Services”). Unless otherwise defined herein, capitalized terms shall have the meaning assigned to such terms in the Terms of Use.
                    </Text>
                    <Text primitive800 xl className={classes.info}>
                        If you have comments, suggestions, questions, or concerns about our Privacy Policy, please contact us at privacy@{frontendInfo?.site_name}.com.
                    </Text>
                </div>

                {/* Information We Collect Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.informationCollect}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Information We Collect
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            When you visit our Site or otherwise engage with us or our representatives, we may collect your e-mail address if you provide it to us voluntarily in order to contact you about the Services, register for our events or other offerings. While some items are optional, most are required for legal and security purposes. To sign up on our Site as a registered user of our Services (“Registered User”) and access the User Restricted Areas, you have to create a user account and provide the following personal information to us during the registration process: your name, your e-mail address and your phone number. You may also voluntarily provide to us information about your investment profile, such as, income level, return expectations, net worth, investment experience, desired investment term, and areas of investment interest.
                        </Text>
                        <Text primitive800 lg className={classes.text}>
                            Registered Users who use our Services to make an investment in our offerings will be asked to provide certain financial data, such as your bank account number and bank routing number. This information is required to enable us or our service providers to utilize your bank account to originate funds transfers and make subsequent investment disbursements to you. We also collect information pertaining to your account transactions. Additionally, for purposes of satisfying “Know Your Customer” requirements, we ask for your identifying information, such as your name, email address, tax identification number, address, telephone number, and date of birth.
                        </Text>
                    </div>
                </div>

                {/* How We Use Your Information Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.useInformation}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            How We Use Your Information
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We may use the information we collect about you from you and the sources described above (collectively, “Information”) for any of the following purposes: (i) to operate the Site and provide the Services; (ii) to respond to your requests and inquiries; (iii) to contact you and to communicate with you about your use of the Services and your Registered User account; (iv) to notify you about important changes to our Services; (v) to review Site usage and operations and to improve the content and functionality of the Site and our Services; (vi) to provide you with information and materials about our products and services; (vii) to address problems with the Services; (viii) to protect the security or integrity of our Services, our Registered Users and our business.
                        </Text>
                    </div>
                </div>

                {/* Sharing Your Information Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.sharingInformation}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Sharing Your Information
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We will not sell or lease your Information to any third party for marketing purposes. YieldStreet will only share your Information: (i) as required by law or regulation; (ii) with our service providers that provide certain services to maintain and operate the Services; and (iii) to any third party service provider or service that is integrated on the Site, in the form of aggregated information or blind data about users of our Services or the Site, which will not include disclosure of any personally identifiable information about you or other users.
                        </Text>
                    </div>
                </div>

                {/* Corporate Business Transactions Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.corporateTransactions}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Corporate Business Transactions
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            As with any other business, it is possible that in the future we may disclose or transfer personal data about you as part of a corporate business transaction, such as a merger or acquisition, joint venture, corporate reorganization, financing, or sale of company assets, or in the unlikely event of insolvency, bankruptcy, or receivership, in which case the personal data could be transferred to third parties as a business asset in the transaction – subject to the terms of this Privacy Policy unless and until it is amended.
                        </Text>
                    </div>
                </div>

                {/* Security of Your Personal Information Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.securityInformation}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Security of Your Personal Information
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We have implemented reasonable technical and organizational measures designed to deter unauthorized access to and use, destruction of, modification of, or disclosure of personally identifiable information we collect via the Site. Regardless of the precautions taken by us or by you, no data transmitted over the Internet or any other public network can be guaranteed to be 100% secure. We cannot ensure or warrant the security of any information you transmit to us and you provide all personally identifiable information via the Site at your own risk.
                        </Text>
                    </div>
                </div>

                {/* Changes to This Privacy Policy Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.policyChanges}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Changes to This Privacy Policy
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We reserve the right at any time to make changes to this Privacy Policy simply by posting such changes or updates on the Site and without any other notice to you. Each time you use the Site, the current version of this Privacy Policy will apply. Your continued use of the Site after we have made any such changes constitutes your acceptance of the most current Privacy Policy, as amended.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicyPage;
