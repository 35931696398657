import { Text } from "components/common";
import React from "react";
import classes from "./TopHeader.module.css";
import { GoDotFill } from "react-icons/go";
import clsx from "clsx";

const TopHeader = ({ step, type, twoFAStatus, selectedVerificationMethod }) => {
  // Determine the length based on the type

  console.log(selectedVerificationMethod);
  const stepsLength =
    type === "Change Password" && selectedVerificationMethod === 3
      ? 3
      : type === "Change Password"
      ? 4
      : type === "Change Email" && selectedVerificationMethod === 3
      ? 4
      : type === "Change Email"
      ? 5
      : type === "2FAEmail" &&
        !twoFAStatus.email &&
        selectedVerificationMethod === 3
      ? 4
      : type === "2FAEmail" && !twoFAStatus.email
      ? 6
      : type === "2FAEmail" &&
        twoFAStatus.email &&
        selectedVerificationMethod === 3
      ? 2
      : type === "2FAEmail" && twoFAStatus.email
      ? 3
      : type === "Phone" &&
        !twoFAStatus.phone &&
        selectedVerificationMethod === 3
      ? 4
      : type === "Phone" && !twoFAStatus.phone
      ? 5
      : type === "Phone" && twoFAStatus.phone
      ? 3
      : type === "Authenticator App" &&
        !twoFAStatus.authenticatorApp &&
        selectedVerificationMethod === 3
      ? 4
      : type === "Authenticator App" && !twoFAStatus.authenticatorApp
      ? 4
      : type === "Authenticator App" &&
        twoFAStatus.authenticatorApp &&
        selectedVerificationMethod === 3
      ? 2
      : type === "Authenticator App" && twoFAStatus.authenticatorApp
      ? 3
      : type === "Google ID" && selectedVerificationMethod === 3
      ? 2
      : type === "Apple ID" && selectedVerificationMethod === 3
      ? 2
      : 3;

  return (
    <>
      <div className={classes.topHeader}>
        <Text base semiBold>
          {type}
        </Text>
        <div className={classes.steps}>
          {Array.from({ length: stepsLength }, (_, index) => (
            <GoDotFill
              key={index}
              className={clsx(
                classes.step,

                step === index + 1 && classes.currentStep,
                step > index + 1 && classes.completedStep
              )}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default TopHeader;
