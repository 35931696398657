import React from "react";
import classes from "./Security.module.css";
import { Button, Text } from "components/common";
import clsx from "clsx";

import { RiEdit2Line } from "react-icons/ri";
const UserInfo = ({ label, value, onClick }) => {
  return (
    <div className={clsx(classes.infoBox, classes.twoFaContainer)}>
      <Text sm primitive500>
        {label}
      </Text>
      <Text textRight font600 primitive700>
        {value || "_"}
      </Text>
      <Button
        transparent
        className={clsx(classes.editButton)}
        onClick={onClick}
      >
        <RiEdit2Line className={classes.icon} />
      </Button>
    </div>
  );
};

export default UserInfo;
