import React from "react";
import classes from "./AboutUs.module.css";
import clsx from "clsx";
import {
  Button,
  Counter,
  Heading,
  Text,
  SectionHeading,
} from "components/common";
import { aboutUsImg } from "images";
import { useNavigate } from "react-router-dom";

const AboutUs = ({ homepage_contents }) => {
  let { about_us } = homepage_contents;
  const navigate = useNavigate();
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.infoContainer}>
          <SectionHeading>{about_us.heading}</SectionHeading>
          <Heading xl5 primitive950>
            {about_us.title}
          </Heading>
          <Text primitive800 xl2>
            {about_us.subtitle}
          </Text>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => navigate("/sign-up")}
              btnPrimary>Join Now</Button>
            <Button
              onClick={() => navigate("/about")} transparent>About us</Button>
          </div>
        </div>
        <div className={classes.imgContainer}>
          <img src={aboutUsImg} alt="About Us" className={classes.img} />
        </div>
      </div>
      <div className={clsx(classes.counter, "container")}>
        <div className={classes.infoBox}>
          <Heading xl6 primitive950 textCenter>
            <Counter start={0} end={12} />+
          </Heading>
          <Text xl textCenter primitive700>
            Years of Experience
          </Text>
        </div>
        <div className={classes.infoBox}>
          <Heading xl6 primitive950 textCenter>
            <Counter start={0} end={5000} />+
          </Heading>
          <Text xl textCenter primitive700>
            Investor Community
          </Text>
        </div>
        <div className={classes.infoBox}>
          <Heading xl6 primitive950 textCenter>
            <Counter start={0} end={20} />
            M+
          </Heading>
          <Text xl textCenter primitive700>
            Profit Generated
          </Text>
        </div>
        <div className={classes.infoBox}>
          <Heading xl6 primitive950 textCenter>
            <Counter start={0} end={99} />%
          </Heading>
          <Text xl textCenter primitive700>
            Success Rate
          </Text>
        </div>
      </div>
    </section >
  );
};

export default AboutUs;
