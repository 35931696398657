import React from "react";
import classes from "./Dashboard.module.css";
import MarketsOverview from "components/Dashboard/MarketsOverview/MarketsOverview";

import InvestmentDetails from "components/Dashboard/InvestmentDetails/InvestmentDetails";
import ActiveInvestment from "components/Dashboard/ActiveInvestments/ActiveInvestment";
import CurrentBalance from "components/Dashboard/CurrentBalance/CurrentBalance";
import DepositModal from "Modals/Dashboard/DepositModal/DepositModal";
import GenerateAddressModal from "Modals/Dashboard/GenerateAddressModal/GenerateAddressModal";
import WithdrawModal from "Modals/Dashboard/WithdrawModal/WithdrawModal";
import { deposit } from "helpers/api/apis";
const Dashboard = ({ positions, userData }) => {
  const [showDepositModal, setShowDepositModal] = React.useState(false);
  const [showGenerateAddressModal, setShowGenerateAddressModal] = React.useState(false);
  const [paymentDetails, setPaymentDetails] = React.useState({});
  const [showWithdrawModal, setShowWithdrawModal] = React.useState(false);

  async function handleDeposit(e) {
    try {
      let { data, status } = await deposit(e);
      if (status != 200) {
        return;
      }
      setPaymentDetails(data);
      setShowGenerateAddressModal(true);
    } catch (e) {
      console.log(e);
    }
  }


  return (

    <>
      <main className={classes.wrapper}>
        <div className={classes.balanceAndInvestmentDetails}>
          <CurrentBalance
            userData={userData}
            openDepositModal={setShowDepositModal}
            setWithModal={setShowWithdrawModal}
            positions={positions} />


          <InvestmentDetails
            positions={positions}
          />
        </div>
        <div className={classes.investmentAndOverview}>
          <ActiveInvestment
            positions={positions}
          />
          <MarketsOverview />
        </div>
      </main>

      <DepositModal
        isActive={showDepositModal}
        onClick={async (e) => {
          // setShowGenerateAddressModal(true);
          await handleDeposit(e);
        }}
        onClose={() => setShowDepositModal(false)}
      />

      <GenerateAddressModal
        paymentDetails={paymentDetails}
        isActive={showGenerateAddressModal}
        onClick={() => {
          // setShowTransactionDetailsModal(true);
        }}
        onClose={() => setShowGenerateAddressModal(false)}
      />{" "}

      <WithdrawModal
        userData={userData}
        isActive={showWithdrawModal}
        onClick={() => {
          // setShowTransactionDetailsModal(true);
        }}
        onClose={() => {
          setShowWithdrawModal(false);
        }} />

    </>
  );
};

export default Dashboard;
