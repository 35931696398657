import React, { useState } from "react";
import classes from "./CurrentBalance.module.css";
import { Button, Heading, Text } from "components/common";
import WithdrawModal from "Modals/Dashboard/WithdrawModal/WithdrawModal";
import TransactionDetailsModal from "Modals/Dashboard/TransactionDetailsModal/TransactionDetailsModal";

const CurrentBalance = ({ positions, userData, openDepositModal, setWithModal }) => {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);
  console.log(positions);
  let active_positions = positions?.filter((position) => position.status.toLowerCase() === "active");
  let completed = positions?.filter((position) => position.status.toLowerCase() === "completed");
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading semiBold base primitive600>
            Current Balance
          </Heading>
          <Heading xl3>
            {userData?.balance} <span className={classes.currency}>USD</span>
          </Heading>
        </div>
        <Button onClick={() => openDepositModal(true)}>
          Deposit
        </Button>
        <Button onClick={() => setWithModal(true)}>
          Withdraw
        </Button>

        <hr className={classes.hr} />
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <Text className={classes.key} primitive700 base>
              Active Positions
            </Text>
            <Text className={classes.value} primitive800 base semiBold>
              {active_positions?.length}
            </Text>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <Text className={classes.key} primitive700 base>
              Completed Positions
            </Text>
            <Text className={classes.value} primitive800 base semiBold>
              {completed?.length}
            </Text>
          </div>
          {/* <div className={classes.spaceBetween}>
            <Text className={classes.key} primitive700 base>
              Total Profits Withdrawn
            </Text>
            <Text className={classes.value} primitive800 base semiBold>
              {
                positions.reduce((acc, position) => {
                  return acc + position.total_profits_withdrawn;
                },
                  0)

              }
            </Text>
          </div>{" "} */}

        </div>{" "}
        <hr className={classes.hr} />
        <Button primitive50 wFull to="/investments-plan">
          Open New Position
        </Button>

      </div>
      <WithdrawModal
        isActive={showWithdrawModal}
        onClose={() => setShowWithdrawModal(false)}
        onClick={() => setShowTransactionDetailsModal(true)}
      />
      <TransactionDetailsModal
        isActive={showTransactionDetailsModal}
        onClose={() => {
          setShowTransactionDetailsModal(false);
        }}
      />
    </>
  );
};

export default CurrentBalance;
