import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const RiskDisclosurePage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>RISK DISCLOSURE</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Invest with Confidence, Understand the Risks
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        At {frontendInfo?.site_name}, we believe that informed investors make the best decisions. While every investment carries an element of risk, understanding these risks empowers you to make confident and well-considered choices. The potential for returns is significant, but it is also essential to be aware of and comfortable with the inherent risks.
                    </Text>
                </div>

                {/* Key Considerations Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.riskDetails}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Key Considerations for Investors
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Investing in any financial market involves certain risks. By understanding these risks, you can better position yourself to make decisions that align with your financial goals. Here are some key considerations:
                        </Text>
                        <ul className={classes.riskList}>
                            <li className={classes.riskItem}>
                                <strong>Market Volatility:</strong> Market conditions can change quickly and significantly, impacting the value of your investments. While this volatility can create opportunities, it also introduces risk to the value of your portfolio. At {frontendInfo?.site_name}, we work diligently to provide you with tools and information to help manage this risk.
                            </li>
                            <li className={classes.riskItem}>
                                <strong>Liquidity Considerations:</strong> Some investments may not be easily converted into cash without affecting their market price. It is essential to evaluate your own liquidity needs before investing to ensure that you can access funds when needed.
                            </li>
                            <li className={classes.riskItem}>
                                <strong>Creditworthiness:</strong> Investments may involve counterparty risk, meaning the issuer or borrowing party may default on its obligations. We strive to provide access to credible and reliable investment opportunities, but it is important to remain mindful of this risk.
                            </li>
                            <li className={classes.riskItem}>
                                <strong>Interest Rate Sensitivity:</strong> Changes in interest rates may have an impact on the value of certain types of investments, particularly fixed-income securities. This is a normal part of economic cycles, and understanding the relationship between interest rates and investment value can help you make informed choices.
                            </li>
                            <li className={classes.riskItem}>
                                <strong>Regulatory Developments:</strong> Changes in regulatory frameworks can impact investment performance. We stay abreast of relevant regulations and keep our investors informed to mitigate uncertainty as much as possible.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Important Reminders Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.importantNotes}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Important Reminders for Investors
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            At {frontendInfo?.site_name}, we are committed to transparency and providing our investors with clear, accurate information. Keep the following in mind:
                        </Text>
                        <ul className={classes.noteList}>
                            <li className={classes.noteItem}>
                                <strong>No Absolute Guarantees:</strong> All investments involve some degree of risk, and returns are never guaranteed. However, by diversifying and employing sound strategies, investors can optimize their potential for positive outcomes.
                            </li>
                            <li className={classes.noteItem}>
                                <strong>Professional Advice is Key:</strong> Consider consulting with financial advisors who understand your unique situation and can provide guidance based on your specific needs and goals. Professional guidance can be invaluable in helping you navigate the complexities of investing.
                            </li>
                            <li className={classes.noteItem}>
                                <strong>Diversification Can Help Manage Risk:</strong> While diversification does not eliminate risk entirely, it can help mitigate the impact of underperforming assets by spreading investments across different types of instruments and sectors.
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Disclaimer Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.disclaimer}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Disclaimer
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            The content provided on this page is intended for informational purposes only and does not constitute financial advice or a recommendation to buy or sell any particular security or asset. {frontendInfo?.site_name} aims to offer a range of quality investment opportunities, but we cannot guarantee any specific outcomes or returns.
                        </Text>
                        <Text primitive800 lg className={classes.text}>
                            All investments come with risks, including the risk of losing principal. It is vital to ensure that your investment choices are well-suited to your risk tolerance and financial circumstances. Our platform provides tools to help you make informed decisions, but always do your research and consult with a qualified financial professional before making any commitments.
                        </Text>
                        <Text primitive800 lg className={classes.text}>
                            By investing through {frontendInfo?.site_name}, you acknowledge that you understand and accept the risks associated with your investment activities. We encourage you to stay informed and make decisions that align with your long-term financial goals.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RiskDisclosurePage;