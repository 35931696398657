import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const TermsOfUsePage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>TERMS OF USE</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Last Updated on May 31, 2024
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        The website {frontendInfo?.site_name} (the “Site”) is brought to you by {frontendInfo?.site_name} (“we,” “us,” or “our”). By using and accessing the Site, you agree to and accept the following terms and conditions of use, including the Mobile Terms set forth hereunder (collectively, the “Terms of Use” or this “User Agreement”). Please read the Terms of Use carefully as they constitute a legally binding contract between you and {frontendInfo?.site_name}.
                    </Text>
                </div>

                {/* Updates/Revisions Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.updatesRevisions}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Updates/Revisions to the Terms of Use
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Please review the Terms of Use periodically, which may be accessed through the Site. We reserve the right, in our sole discretion, to update or revise the Terms of Use at any time. By using or accessing the Site, you agree that we may modify the Terms of Use or the Site at any time without prior notice. Your continued use of the Site following the posting of updates and/or revisions will conclusively indicate your acceptance of the updated or revised Terms of Use.
                        </Text>
                    </div>
                </div>

                {/* Security of Username/Password Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.securityCredentials}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Security of Your Username/Password
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Access to certain areas of the Services (“User Restricted Areas”) is enabled only by username and password. You shall maintain your password in strict confidence and shall not share it with any third party. You are fully responsible for all use of the Services using your username and password. We reserve the right to revoke or deactivate your username and password if you violate the Terms of Use.
                        </Text>
                    </div>
                </div>

                {/* Use of Content Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.useContent}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Content Made Available on or Through the Site and Your Use of Content
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            The Site contains a wide variety of Content, whether proprietary to {frontendInfo?.site_name} or third parties. You acknowledge and agree that all Content is owned by {frontendInfo?.site_name} or others and is protected by copyright, trademark, and other intellectual property laws. You may use Content solely for your information and personal, non-commercial use. Modification, reproduction, or distribution of Content without prior consent is strictly prohibited.
                        </Text>
                    </div>
                </div>

                {/* Confidentiality Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.confidentiality}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Non-Disclosure and Confidentiality
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            In using the Services, you will have access to confidential information. You agree to maintain and safeguard the privacy and security of all such confidential information and use it only for the purposes for which it was made available.
                        </Text>
                    </div>
                </div>

                {/* Restrictions Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.restrictions}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Restrictions on Your Use of the Services
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            You agree not to access or use the Services in an unlawful way or for an unlawful purpose. You shall not post or transmit information that is unlawful, defamatory, obscene, or infringes upon the rights of others. You shall not attempt to disrupt the operation of the Services or use any means to access restricted areas without authorization.
                        </Text>
                    </div>
                </div>

                {/* Disclaimer Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.disclaimer}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Disclaimer
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Your use of the Services is at your sole risk. The Services are provided on an "AS IS" and "AS AVAILABLE" basis. We do not guarantee the accuracy, safety, integrity, or quality of Content, and you must evaluate and bear all risks associated with your use of the Services.
                        </Text>
                    </div>
                </div>

                {/* Termination Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.termination}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Termination/Exclusion
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            We may modify, suspend, or terminate all or a portion of the Services at any time without prior notice to you. We reserve the right to revoke or terminate your access if you fail to comply with these Terms of Use.
                        </Text>
                    </div>
                </div>

                {/* Governing Law Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.governingLaw}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Governing Law and Jurisdiction
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            This User Agreement shall be governed by the laws of the State of New York without regard to its conflict of law principles. By using the Site, you agree to submit to the jurisdiction of the New York courts for any matters arising out of or related to these Terms of Use.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsOfUsePage;
