import React from "react";
import classes from "./OurValues.module.css";
import clsx from "clsx";
import { Button, Heading, SectionHeading, Text } from "components/common";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const OurValues = ({ homepage_contents }) => {
  let { our_values } = homepage_contents;
  let navigate = useNavigate();
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.topHeader}>
          <div className={classes.header}>
            <SectionHeading>{our_values.title}</SectionHeading>
            <Heading xl5 primitive950>
              {our_values.heading}
            </Heading>
            <Text primitive800 xl2 semiBold>
              {our_values.subtitle}
            </Text>
          </div>{" "}
          <Button
            onClick={() => navigate("/sign-up")}
            btnPrimary className={classes.button}>
            Join Now
          </Button>
        </div>

        <div className={classes.facilities}>
          {our_values.facilities.map((el, i) => (
            <div className={classes.facility} key={i}>
              <FaRegCircleCheck className={classes.icon} />
              <Heading xl2>{el.title}</Heading>
              <Text primitive800 xl>
                {el.info}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurValues;
