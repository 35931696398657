import React from "react";
import { Outlet } from "react-router-dom";
import classes from "./DashBoardLayout.module.css";
import clsx from "clsx";
import Header from "./Header/Header";
import Navbar from "Layout/Navbar/Navbar";

const DashBoardLayout = ({ isLoggedIn, setIsLoggedIn }) => {
  return (
    <main className={classes.wrapper}>
      <div className={classes.container}>
        <Navbar isLoggedIn={isLoggedIn} primitive950 />
        <Header setIsLoggedIn={setIsLoggedIn} />
        <div className={clsx(classes.outlet, "container")}>
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default DashBoardLayout;
