import React, { useEffect, useState } from "react";
import classes from "./Chart.module.css";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Button, Text } from "components/common";
import clsx from "clsx";
import { get_earnings, get_plan, get_plans } from "helpers/api/apis";

// Sample data with year included
const allData = [{ date: "Aug 6, 2024", invest: 3000, profit: 1200 }];

const Chart = ({ setShowWithdrawModal, type2, el }) => {
  const [earnings, setEarnings] = useState(0);
  const [planData, setPlanData] = useState({});
  const [allData, setAllData] = useState([
    {
      date: "Aug 6, 2024",
      invest: el?.capital,
      profit: el?.total_profits_withdrawn + earnings,
    },
  ]);

  async function fetch_earnings() {
    console.log(`Fetching earnings for investment with id ${el?.id}...`);
    let { data } = await get_earnings(el?.id);
    console.log("earnings", data);
    setEarnings(data);

    setAllData([
      {
        date: "Aug 6, 2024",
        invest: el?.capital,
        profit: el?.total_profits_withdrawn + data,
      },
    ]);
  }
  useEffect(() => {
    fetch_earnings();
  }, [el]);

  async function get_plan_data() {
    let plan = el?.plan_id;

    let data = await get_plans();
    let find = data?.data.find(
      (item) => item.id.trim().toLowerCase() === plan.trim().toLowerCase()
    );
    console.log("plan data", find);
    setPlanData(find);
  }
  useEffect(() => {
    get_plan_data();
  }, [el]);

  const [selectedDate, setSelectedDate] = useState("Aug 6, 2024"); // Default selected date

  function calculateGraph() {
    let start_date = new Date(el?.created_at_timestamp * 1000);
    let end_date = new Date(el?.end_date_timestamp * 1000);
    let now = new Date();
    let now_timestamp = now.getTime();
    let reward_per_cycle = el?.reward_per_cycle;

    let cycle;
    if (el?.interest_schedule.toLowerCase() === "daily") {
      cycle = 86400;
    } else if (el?.interest_schedule.toLowerCase() === "weekly") {
      cycle = 86400 * 7;
    } else if (el?.interest_schedule.toLowerCase() === "monthly") {
      cycle = 86400 * 30;
    } else if (el?.interest_schedule.toLowerCase() === "yearly") {
      cycle = 86400 * 365;
    }
    let data = [];
    console.log("cycle", cycle);

    console.log("now timestamp", now_timestamp);
    console.log("start date", start_date.getTime());
    let total_cycles = (now_timestamp - start_date.getTime()) / 1000;
    console.log(total_cycles / cycle);
  }

  useEffect(() => {
    calculateGraph();
  }, []);

  const handleBarClick = (data) => {
    setSelectedDate(data.payload.date);
  };

  useEffect(() => {
    calculateTotals(allData);
  }, [el]);
  return (
    <section className={clsx(classes.wrapper, type2 && classes.type2)}>
      <div>
        <div className={classes.investAmount}>
          <p className={classes.investBar}></p>{" "}
          <Text xs={!type2} base={type2} semiBold primitive600 textLeft>
            Capital
          </Text>
          <Text xs={!type2} base={type2} semiBold textRight primitive950>
            ${el?.capital}
          </Text>
          {planData?.refund_capital == true && (
            <Button
              transparent
              sm
              className={classes.withdrawButton}
              onClick={() => setShowWithdrawModal(true)}
            >
              Withdraw
            </Button>
          )}
        </div>

        <div className={classes.profitAmount}>
          <p className={classes.profitBar}></p>{" "}
          <Text xs={!type2} base={type2} semiBold textLeft primitive600>
            Accumulated Earnings
          </Text>
          <Text xs={!type2} base={type2} semiBold textRight primitive950>
            ${el?.total_profits_withdrawn + earnings}{" "}
          </Text>{" "}
          {type2 && (
            <Button
              transparent
              sm
              className={classes.withdrawButton}
              onClick={() => setShowWithdrawModal(true)}
            >
              Withdraw
            </Button>
          )}
        </div>
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer width="100%" height={80}>
          <BarChart
            data={allData}
            earnings={earnings}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Bar
              dataKey="invest"
              stackId="a"
              fill="#4669f5"
              radius={[0, 0, 0, 0]}
              isAnimationActive={false}
              onClick={handleBarClick}
              cursor="pointer"
              barSize={50}
            />
            <Bar
              dataKey="profit"
              stackId="a"
              fill="#22c55e"
              radius={[2, 2, 0, 0]}
              isAnimationActive={false}
              onClick={handleBarClick}
              cursor="pointer"
              barSize={50}
            />
          </BarChart>
        </ResponsiveContainer>
        <div className={classes.investAndProfit}>
          {/* <Text xs semiBold primitive400>
            {selectedDate}
          </Text> */}
          {/* <Text xs semiBold primitive400 className={classes.invest}>
            $
            {allData.find((data) => data.date === selectedDate)?.invest ||
              "N/A"}
          </Text>
          <Text xs semiBold primitive400 className={classes.profit}>
            $
            {allData.find((data) => data.date === selectedDate)?.profit ||
              "N/A"}
          </Text> */}
        </div>
      </div>
    </section>
  );
};

export default Chart;
// Calculate total investment and profit

const calculateTotals = (data) => {
  return data.reduce(
    (totals, entry) => {
      if (entry.invest !== null) totals.invest += entry.invest;
      if (entry.profit !== null) totals.profit += entry.profit;
      return totals;
    },
    { invest: 0, profit: 0 }
  );
};
