import React from "react";
import classes from "./HowToStart.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";
import {
  chooseAccIcon,
  choosePlan,
  depositFund,
  marketOverviewImg,
} from "images";

const HowToStart = ({ homepage_contents }) => {
  let { how_to_start } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <SectionHeading>{how_to_start.header}</SectionHeading>
          <Heading xl5 primitive0>
            {how_to_start.title}
          </Heading>
        </div>

        <div className={classes.steps}>
          {how_to_start?.steps?.map((el, i) => (
            <div className={classes.step} key={i}>
              <Text xl2 primitive0 className={classes.id}>
                {i + 1}
              </Text>
              <img src={el.icon} alt="#" className={classes.icon} />
              <Heading xl3 semiBold primitive900>
                {el.title}
              </Heading>
              <Text xl primitive900 className={classes.info}>
                {el.info}
              </Text>
              <Text />
            </div>
          ))}
        </div>

        <div className={classes.getYourInterested}>
          <div className={classes.infoContainer}>
            <Heading xl3 font600 primitive0>
              {how_to_start.mini_header}
            </Heading>
            <Text xl semiBold primitive300>
              {how_to_start.mini_subtitle}
            </Text>
          </div>
          <div className={classes.imgContainer}>
            <img src={marketOverviewImg} alt="#" className={classes.img} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToStart;
