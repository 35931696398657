import React from "react";
import classes from "./UniqueReferralLink.module.css";
import { Button, Heading, Text } from "components/common";
import { qrCodeIcon } from "images";
import { copyToClipboard } from "utils/utils";

const UniqueReferralLink = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg semiBold>
          Your Unique Referral Link
        </Heading>
        <Text sm primitive700>
          Share this link with your friends. When they sign up and start
          investing, you’ll earn rewards!
        </Text>
      </div>
      <div className={classes.linkWrapper}>
        <div className={classes.linkContainer}>
          <Text base primitive700 semiBold className={classes.link}>
            http://example.com/abc123
          </Text>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            wFull
            onClick={() => copyToClipboard("http://example.com/abc123")}
          >
            Copy Link
          </Button>
          <button className={classes.qrCodeContainer}>
            <img src={qrCodeIcon} alt="#" className={classes.qrCodeIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UniqueReferralLink;
