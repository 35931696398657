import React from "react";
import classes from "./ReferralList.module.css";
import clsx from "clsx";
import { Heading, Text } from "components/common";

const data = [
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "Pending Sign-Up",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "Pending Sign-Up",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "invested",
  },
  {
    referralName: "Kelsi Bernier",
    date: "30 Sep 2024",
    earnings: "$3800.00",
    status: "Pending Sign-Up",
  },
];
const ReferralList = () => {
  return (
    <main className={clsx(classes.wrapper)}>
      <Heading semiBold xl primitive950>
        Investments List
      </Heading>

      <div className={clsx(classes.tableContainer, "overflow")}>
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              <th className={classes.heading}>Referral Name</th>

              <th className={classes.heading}>Date</th>

              <th className={clsx(classes.heading)}>Status</th>
              <th className={clsx(classes.heading, classes.textRight)}>
                Earnings
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className={clsx(classes.row)}>
                <td className={classes.item}>{item.referralName}</td>

                <td className={classes.item}>{item.date}</td>

                <td className={classes.item}>
                  <div className={classes.statusContainer}>
                    <Text
                      primitive0
                      sm
                      font600
                      className={clsx(
                        classes.status,
                        item.status.toLowerCase() === "invested"
                          ? classes.invested
                          : item.status.toLowerCase() === "pending sign-up"
                          ? classes.pending
                          : ""
                      )}
                    >
                      {item.status}
                    </Text>
                  </div>
                </td>
                <td className={clsx(classes.item, classes.textRight)}>
                  {item.earnings}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Text textCenter sm primitive500 className={classes.loading}>
        Loading...
      </Text>
    </main>
  );
};

export default ReferralList;
