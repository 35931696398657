import React from "react";
import classes from "./AvailableRewards.module.css";
import { Button, Heading, Text } from "components/common";

const AvailableRewards = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading semiBold base primitive600>
          Available Rewards
        </Heading>
        <Heading xl3>
          1,821.00 <span className={classes.currency}>USD</span>
        </Heading>
      </div>
      <Button wFull>Withdraw</Button>
      <hr className={classes.hr} />
      <div className={classes.referralInfo}>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Total Referrals
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            3,849
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Successful Referrals
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            1,268
          </Text>
        </div>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Pending Referrals
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            169
          </Text>
        </div>
      </div>{" "}
      <hr className={classes.hr} />
      <div className={classes.earningsInfo}>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Earnings to Date
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            8,547.00
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Pending Rewards
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            381.00
          </Text>
        </div>
        <div className={classes.spaceBetween}>
          <Text className={classes.key} primitive700 base>
            Total withdrawn
          </Text>
          <Text className={classes.value} primitive800 base semiBold>
            6,726.00
          </Text>
        </div>
      </div>
    </div>
  );
};

export default AvailableRewards;
