import React from "react";
import classes from "./TransactionDetailsModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Text } from "components/common";
import clsx from "clsx";

const TransactionDetailsModal = ({ isActive, onClose, investment }) => {
  const status = "completed";
  const type = "withdraw";
  const info = [
    {
      key: "Investment ID",
      value: investment?.id
    },
    {
      key: "Plan ID",
      value: investment?.plan_id
    },
    {
      key: "Start Date",
      value: new Date(investment?.created_at_timestamp * 1000).toLocaleString(),
    },
    {
      key: "End Date",
      value: new Date(investment?.end_date_timestamp * 1000).toLocaleString(),
    },
  ];
  const amountInfo = [
    {
      key: "Payment Method",
      value: "USD Balance",
    },
    {
      key: "Capital",
      value: investment?.capital / Math.pow(10, 8) + " USD",
    },
    // {
    //   key: "Crypto Amount",
    //   value: "0.06598 BTC",
    // },
    // {
    //   key: "Platform Fee",
    //   value: "1.00 USD",
    // },
  ];
  return (
    <>
      <Modal
        isActive={isActive}
        onClose={onClose}
        heading="Transactions Details"
      >
        <div className={classes.wrapper}>
          <div className={classes.infoContainer}>
            {/* <div className={classes.spaceBetween}>
              <Text primitive600 base className={classes.key}>
                Type
              </Text>
              <Text
                base
                semiBold
                className={clsx(
                  classes.value,
                  type.toLowerCase() === "withdraw" && classes.withdraw,
                  type.toLowerCase() === "deposit" && classes.deposit
                )}
              >
                {type}
              </Text>
            </div>{" "} */}
            <div className={classes.spaceBetween}>
              <Text primitive600 sm className={classes.key}>
                Status
              </Text>
              <Text
                base
                semiBold
                primitive0
                className={clsx(
                  classes.status,
                  status.toLowerCase() === "completed"
                    ? classes.completed
                    : status.toLowerCase() === "pending"
                      ? classes.pending
                      : status.toLowerCase() === "failed"
                        ? classes.failed
                        : ""
                )}
              >
                {status}
              </Text>
            </div>
          </div>

          <div className={classes.infoContainer}>
            {info.map((el, i) => (
              <div className={classes.spaceBetween} key={i}>
                <Text primitive600 base className={classes.key}>
                  {el.key}
                </Text>
                <Text
                  base
                  semiBold
                  primitive800
                  className={clsx(classes.value)}
                >
                  {el.value}
                </Text>
              </div>
            ))}
          </div>
          <div className={classes.infoContainer}>
            {amountInfo.map((el, i) => (
              <div className={classes.spaceBetween} key={i}>
                <Text primitive600 base className={classes.key}>
                  {el.key}
                </Text>
                <Text
                  base
                  semiBold
                  primitive800
                  className={clsx(classes.value)}
                >
                  {el.value}
                </Text>
              </div>
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button primitive50 onClick={onClose} 
            className={classes.button}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransactionDetailsModal;
