import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";

import {
  Button,
  Counter,
  Heading,
  Text,
  SectionHeading,
} from "components/common";
import { GoArrowUpRight } from "react-icons/go";
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  boxs,
  growth,
  smallLogo,
} from "images";
import { useNavigate } from "react-router-dom";

const HeroSection = ({ isLoggedIn, frontendInfo, homepage_contents }) => {
  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5];
  let hero = homepage_contents.hero;
  const navigate = useNavigate();
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container)}>
        <div className={classes.infoWrapper}>
          <div className={classes.infoContiner}>
            <SectionHeading>{homepage_contents.hero.heading}</SectionHeading>
            <Heading xl6>{hero.title}</Heading>

            <Text primitive800 xl2>
              {hero.subtitle}
            </Text>
            <div className={classes.buttonContainer}>
              <Button

                onClick={() => {
                  if (isLoggedIn) {
                    navigate("/dashboard")
                  } else {
                    navigate("/sign-up")
                  }
                }}
                btnPrimary className={classes.joinNow}>
                {/* {hero.first_button}{" "} */}
                {isLoggedIn ? "Dashboard" : "Join Now"}
                <p className={classes.linkContainer}>
                  <GoArrowUpRight />
                </p>{" "}
              </Button>
              <Button
                onClick={() => navigate("/about")} transparent>{hero.second_button}</Button>
            </div>
          </div>
          <div className={classes.smallBox}></div>
        </div>
        <div className={clsx(classes.ourCommunity, classes.box)}>
          <div className={classes.content}>
            <Text xl semiBold primitive0>
              Join the largest investment community with
            </Text>{" "}
            <Heading xl4 bold primitive0>
              {frontendInfo?.site_name}
            </Heading>
            <div className={classes.avatars}>
              {avatars.map((avatar, i) => (
                <div
                  key={i}
                  className={classes.avatarContainer}
                  style={{
                    marginLeft: i === 0 ? 0 : "-24px",
                    border: i === 0 && 0,
                  }}
                >
                  <img src={avatar} alt="Avatar" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={clsx(classes.logoContainer, classes.box)}>
          <img src={smallLogo} alt="#" className={classes.logo} />
        </div>{" "}
        <div className={clsx(classes.growthContainer)}>
          <img src={growth} alt="#" className={classes.growthImg} />
        </div>{" "}
        <div className={clsx(classes.testimonial, classes.box)}>
          <div className={classes.content}>
            <img src={avatar5} alt="#" className={classes.avatar} />
            <Text xl semiBold primitive100>
              {/* "Brand name platform is always my only go-to option for trustful
              investments." */}
              {frontendInfo?.site_name} platform is always my only go-to option for trustful
              investments
            </Text>{" "}
          </div>
        </div>
        <div className={clsx(classes.cryptoSupported, classes.box)}>
          <div className={classes.content}>
            <Heading xl7 primitive0>
              <Counter start={0} end={60} />+
            </Heading>
            <Text xl semiBold primitive0>
              Crypto Supported
            </Text>
            <Text xl semiBold primitive0 className={classes.mtAuto}>
              Invest in our platform. And Get highest interest rate.
            </Text>
          </div>
        </div>
        <div className={clsx(classes.boxs)}>
          <img src={boxs} alt="#" className={classes.boxImg} />
        </div>{" "}
      </div>
    </section>
  );
};

export default HeroSection;
