import React, { useState } from "react";
import classes from "./ContactPage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";
import CustormerSupport from "components/Landing/Contact/CustormerSupport/CustormerSupport";
import ProvideInformation from "components/Landing/Contact/ProvideInformation/ProvideInformation";
import LeaveMessage from "components/Landing/Contact/LeaveMessage/LeaveMessage";
import WhyChooseUs from "components/Landing/Contact/WhyChooseUs/WhyChooseUs";

const ContactPage = ({ frontendInfo }) => {
  const [step, setStep] = useState(1);
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <SectionHeading>CONTACT</SectionHeading>
          <Heading xl6 primitive950 className={classes.heading}>
            We're Here to Help
          </Heading>
          <Text primitive800 xl className={classes.info}>
            At {frontendInfo?.site_name}, we value your feedback, questions, and concerns.
            Whether you're an existing investor or just exploring our platform,
            our team is here to assist you every step of the way.
          </Text>
        </div>
        <div className={classes.content}>
          <CustormerSupport frontendInfo={frontendInfo} />
          <div className={classes.stepContainer}>
            {step === 1 && <ProvideInformation frontendInfo={frontendInfo} setStep={setStep} />}
            {step === 2 && <LeaveMessage setStep={setStep} />}
          </div>
        </div>
      </div>
      {/* <WhyChooseUs /> */}
    </section>
  );
};

export default ContactPage;
