import React from "react";
import classes from "./CustormerSupport.module.css";
import { Heading, Text } from "components/common";

const CustormerSupport = ({ frontendInfo }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl2>Customer Support:</Heading>
        <Text lg primitive800>
          For general inquiries, account issues, or support, please reach out to
          our dedicated customer support team. We're available 24/7 to ensure
          your experience with us is smooth and successful.
        </Text>
      </div>
      <div className={classes.mobileAndAdress}>
        <Text xl semiBold primitive950>
          <a href="tel:+1 234 567 890">{frontendInfo?.phone_number}</a>
          <br />
          <a href="mailto: hello@brandname.com">{frontendInfo?.email} </a>
        </Text>
        <Text xl semiBold primitive950>
          {frontendInfo?.address}
        </Text>
      </div>{" "}
      <div className={classes.officeInfo}>
        <Heading lg>Office Hours:</Heading>
        <div>
          <Text lg semiBold primitive950>
            Monday to Friday
          </Text>
          <Text base primitive500 semiBold>
            8:00 AM - 6:00 PM (EST)
          </Text>
        </div>{" "}
        <div>
          <Text lg semiBold primitive950>
            Saturday, Sunday
          </Text>
          <Text base primitive500 semiBold>
            Closed
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CustormerSupport;
