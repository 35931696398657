import React, { useState } from "react";
import classes from "./Header.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";

import { IoMdClose } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa6";
import Cookies from "js-cookie";
import MyAccountModal from "Modals/Account/MyAccountModal/MyAccountModal";
const Header = ({ setIsLoggedIn }) => {
  const [sidebar, setSidebar] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState("Dashboard");
  const navitems = [
    { navItem: "Dashboard", to: "/dashboard" },
    { navItem: "Investments Plans", to: "/investments-plan" },
    { navItem: "My Investments", to: "/my-investments" },
    { navItem: "History", to: "/transaction" },
    // { navItem: "Referral", to: "/referral" },
  ];

  const navigate = useNavigate();
  const [showMyAccout, setShowMyAccount] = useState(false);
  return (
    <>
      <header className={classes.wrapper}>
        <nav
          className={clsx(classes.navbar, "container")}
          onClick={() => {
            if (!sidebar) {
              setSidebar(true);
            } else {
              setSidebar(false);
            }
          }}
        >
          <p className={clsx(classes.navItem, classes.forMobile)}>
            {activeNavItem}
          </p>

          <div className={clsx(classes.navItems, sidebar && classes.sidebar)}>
            {navitems.map((el, i) => (
              <NavLink
                key={i}
                to={el.to}
                className={({ isActive }) =>
                  isActive
                    ? clsx(classes.navActive, classes.navItem)
                    : classes.navItem
                }
                onClick={() => setActiveNavItem(el.navItem)}
              >
                {el.navItem}
              </NavLink>
            ))}
            <button
              className={clsx(classes.navItem, classes.myAccount)}
              onClick={() => {
                setActiveNavItem("My Account");
                setShowMyAccount(true);
              }}
            >
              My Account
            </button>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? clsx(
                    classes.navActive,
                    classes.navItem,
                    classes.helpAndSupprt
                  )
                  : clsx(classes.navItem, classes.helpAndSupprt)
              }
              // onClick={() => setActiveNavItem("Help & Support")}
              onClick={() => {
                Cookies.remove("token");
                localStorage.removeItem("isLoggedIn");
                setIsLoggedIn(false);
                // window("/login")
                // navigate("/login");
                window.location.href = "/";
              }}
            >
              Logout
            </NavLink>
            {sidebar && (
              <IoMdClose
                className={clsx(classes.icon, classes.close)}
                onClick={() => {
                  if (sidebar) {
                    setSidebar(false);
                  }
                }}
              />
            )}
          </div>
          <div className={classes.iconContainer}>
            {!sidebar && (
              <FaChevronDown
                className={clsx(classes.icon)}
                onClick={() => {
                  if (!sidebar) {
                    setSidebar(true);
                  }
                }}
              />
            )}
          </div>
        </nav>
      </header>
      <MyAccountModal
        isActive={showMyAccout}
        onClose={() => setShowMyAccount(false)}
      />
    </>
  );
};

export default Header;
