import { Heading, Text } from "components/common";
import React, { useEffect, useState } from "react";
import classes from "./InvestmentsPlan.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Plans from "components/Landing/Home/FlexibleDepositPlan/Plans/Plans";
import { get_plans } from "helpers/api/apis";
const InvestmentsPlan = ({ userData, plans }) => {


  return (
    <main className={clsx(classes.wrapper)}>
      <div className={classes.header}>
        <div className={classes.titleAndInfo}>
          <Heading xl5 primitive950>
            Select the right plan for your needs
          </Heading>
          <Text lg primitive800>
            Select a membership plan that aligns with your investment goals.
            Whether you're just starting or an experienced investor, we have a
            plan that fits your needs.
          </Text>
        </div>
        <div className={classes.needHelp}>
          <Text base primitive500>
            Need any help?
          </Text>
          <Text primitive900 lg>
            <Link className={classes.link} to="/contact">
              Contact Us
            </Link>
          </Text>
        </div>
      </div>
      <Plans userData={userData} plans={plans} />
    </main>
  );
};

export default InvestmentsPlan;
