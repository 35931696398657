import React from "react";
import classes from "./PasswordChanged.module.css";
import { passwordChanged } from "images";
import { Button, Heading, Text } from "components/common";
const PasswordChanged = () => {
  return (
    <div className={classes.wrapper}>
      <img src={passwordChanged} alt="#" className={classes.img} />
      <div className={classes.infoContainer}>
        <Heading xl3 font600 textCenter>
          Password Changed
        </Heading>
        <Text lg primitive700 textCenter>
          Your password is successfully changed. You can login to your account
          with the new password.
        </Text>
      </div>
      <Button to="/dashboard">Go to Dashboard</Button>
    </div>
  );
};

export default PasswordChanged;
