import React, { useRef, useState } from "react";
import classes from "./WhatInvestorsSay.module.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";

import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  quotationIcon,
} from "images";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const WhatInvestorsSay = ({ homepage_contents }) => {
  const { testimonials } = homepage_contents;
  const swiperRef = useRef(null); // Reference to the Swiper instance
  const [activeIndex, setActiveIndex] = useState(0); // State to track active slide index

  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.swiper.slideNext();
  };

  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.topHeader}>
          {" "}
          <div className={classes.header}>
            <SectionHeading>{testimonials.heading}</SectionHeading>
            <Heading xl6>{testimonials.title}</Heading>
            <Text xl2 primitive800>
              {testimonials.subtitle}
            </Text>
          </div>
          <div className={classes.nextAndPrevButton}>
            <button
              onClick={handlePrev}
              className={clsx(classes.prevButton, {
                [classes.activeButton]: activeIndex !== 0,
              })}
            >
              <FaArrowLeftLong className={classes.arrow} />
            </button>
            <button
              onClick={handleNext}
              className={clsx(classes.nextButton, {
                [classes.activeButton]:
                  activeIndex <
                  testimonials.user_testimonials.length -
                  Math.floor(
                    swiperRef.current?.swiper.params.slidesPerView || 0
                  ),
              })}
            >
              <FaArrowRightLong className={classes.arrow} />
            </button>
          </div>
        </div>

        <div className={classes.cards}>
          <Swiper
            ref={swiperRef}
            loop={true}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            navigation={false}
            slidesPerView={1}
            effect={"fade"}
            spaceBetween={16}
            scrollbar={{
              hide: true,
            }}
            className={clsx("collections-swiper", classes.swiper)}
            breakpoints={{
              520: {
                slidesPerView: 1.4,
                spaceBetween: 16,
              },
              767: {
                slidesPerView: 2.3,
                spaceBetween: 16,
              },
              991: {
                slidesPerView: 2.5,
                spaceBetween: 16,
              },
              1199: {
                slidesPerView: 2.8,
                spaceBetween: 16,
              },
              1280: {
                slidesPerView: 3.2,
                spaceBetween: 16,
              },
              1440: {
                slidesPerView: 4.2,
                spaceBetween: 16,
              },
            }}
          >
            {testimonials.user_testimonials.map((el, index) => (
              <SwiperSlide key={index}>
                <div className={classes.card}>
                  <img
                    src={quotationIcon}
                    alt="#"
                    className={classes.quotationIcon}
                  />
                  <Text lg primitive950>
                    {el.info}
                  </Text>
                  <div className={classes.authorInfo}>
                    <img src={el.img} alt="#" className={classes.authorImg} />
                    <div>
                      <Text semiBold lg primitive800>
                        {el.author}
                      </Text>
                      <Text sm primitive500>
                        {el.userName}
                      </Text>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default WhatInvestorsSay;
