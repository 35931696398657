import { Button, Heading, Text } from "components/common";
import React from "react";
import classes from "./Plans.module.css";
import clsx from "clsx";
import SinglePlan from "./SinglePlan";
const Plans = ({ plans, userData }) => {
  // const plans = [
  //   {
  //     type: "Starter",
  //     popular: false,
  //     dailyInterst: {
  //       percentage: "4%",
  //       duration: "4 weeks",
  //     },
  //     info: "Low-risk, ideal for beginners or new investors, with a minimum deposit of $500",
  //     depositInfo: [
  //       {
  //         key: "Min. Deposit",
  //         value: "$500",
  //       },
  //       {
  //         key: "Max. Deposit",
  //         value: "$4,999",
  //       },
  //       {
  //         key: "Duration",
  //         value: "4 weeks",
  //       },
  //       {
  //         key: "Capital Return",
  //         value: "No",
  //       },
  //       {
  //         key: "Status",
  //         value: "Active",
  //       },
  //     ],
  //   },
  //   {
  //     type: "Basic",
  //     popular: false,
  //     dailyInterst: {
  //       percentage: "6%",
  //       duration: "4 weeks",
  //     },
  //     info: "Low-risk, ideal for beginners or new investors, with a minimum deposit of $500",
  //     depositInfo: [
  //       {
  //         key: "Min. Deposit",
  //         value: "$500",
  //       },
  //       {
  //         key: "Max. Deposit",
  //         value: "$4,999",
  //       },
  //       {
  //         key: "Duration",
  //         value: "4 weeks",
  //       },
  //       {
  //         key: "Capital Return",
  //         value: "No",
  //       },
  //       {
  //         key: "Status",
  //         value: "Active",
  //       },
  //     ],
  //   },
  //   {
  //     type: "Business",
  //     popular: true,
  //     dailyInterst: {
  //       percentage: "8%",
  //       duration: "4 weeks",
  //     },
  //     info: "Low-risk, ideal for beginners or new investors, with a minimum deposit of $500",
  //     depositInfo: [
  //       {
  //         key: "Min. Deposit",
  //         value: "$500",
  //       },
  //       {
  //         key: "Max. Deposit",
  //         value: "$4,999",
  //       },
  //       {
  //         key: "Duration",
  //         value: "4 weeks",
  //       },
  //       {
  //         key: "Capital Return",
  //         value: "No",
  //       },
  //       {
  //         key: "Status",
  //         value: "Active",
  //       },
  //     ],
  //   },
  //   {
  //     type: "Enterprise",
  //     popular: false,
  //     dailyInterst: {
  //       percentage: "10%",
  //       duration: "4 weeks",
  //     },
  //     info: "Low-risk, ideal for beginners or new investors, with a minimum deposit of $500",
  //     depositInfo: [
  //       {
  //         key: "Min. Deposit",
  //         value: "$500",
  //       },
  //       {
  //         key: "Max. Deposit",
  //         value: "$4,999",
  //       },
  //       {
  //         key: "Duration",
  //         value: "4 weeks",
  //       },
  //       {
  //         key: "Capital Return",
  //         value: "No",
  //       },
  //       {
  //         key: "Status",
  //         value: "Active",
  //       },
  //     ],
  //   },
  // ];
  return (
    <div className={classes.cards}>
      {plans?.map((el, i) => (
        <SinglePlan userData={userData} el={el} key={i} />
      ))}
    </div>
  );
};

export default Plans;
