import React from "react";
import classes from "./ReadyToStart.module.css";
import clsx from "clsx";
import { Button, Heading, Text } from "components/common";
import { readyToStartImg } from "images";

const ReadyToStart = ({ homepage_contents }) => {
  let { ready_to_start } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <Heading xl7 className={classes.heading} primitive0>
            {ready_to_start.heading}
          </Heading>
          <div className={classes.infoContainer}>
            <Text xl2 primitive300>
              {ready_to_start.subtitle}
            </Text>
            <Button btnPrimary>Join Now</Button>
          </div>
        </div>
        {/* <img src={readyToStartImg} alt="#" className={classes.img} /> */}
      </div>
    </section>
  );
};

export default ReadyToStart;
