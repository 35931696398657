import { Button, Heading, Text } from "components/common";
import React, { useState } from "react";
import classes from "./Plans.module.css";
import clsx from "clsx";
import CreateInvestmentsModal from "Modals/Dashboard/CreateInvestmentsModal/CreateInvestmentsModal";
import DepositModal from "Modals/Dashboard/DepositModal/DepositModal";
import GenerateAddressModal from "Modals/Dashboard/GenerateAddressModal/GenerateAddressModal";
import TransactionDetailsModal from "Modals/Dashboard/TransactionDetailsModal/TransactionDetailsModal";
import { open_position } from "helpers/api/apis";
import { toast } from "react-toastify";
const SinglePlan = ({ el, userData }) => {
  const [showCreateInvestmentModal, setShowCreateInvestmentModal] =
    useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showGenerateAddressModal, setShowGenerateAddressModal] =
    useState(false);
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);
  let sufficientBalance = true;

  const [amount, setAmount] = useState();

  const [newInvestment, setNewInvestment] = useState({});
  // let sufficientBalance = userData?.balance >= el?.minimum_amount;
  return (
    <>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <Text sm primitive0 font600 className={classes.type}>
            {el?.name}
          </Text>
          {el.popular && (
            <Text sm primitive600 font600 className={classes.popular}>
              Popular
            </Text>
          )}
        </div>
        <Heading xl4>
          {el?.interest_rate}%
          <span className={classes.dailyInterest}> {el?.interest_schedule} interest</span>
          <Text semiBold xl primitive800 className={classes.interestDuration}>
            For {el.duration} {
              el.duration_in.toLowerCase() == "days" && el.duration == 1 ? "day" : el.duration_in.toLowerCase() == "days" ? "days" : el.duration_in.toLowerCase() == "weeks" && el.duration == 1 ? "week" : el.duration_in.toLowerCase() == "weeks" ? "weeks" : el.duration_in.toLowerCase() == "months" && el.duration == 1 ? "month" : el.duration_in.toLowerCase() == "months" ? "months" : ""
            }
          </Text>
        </Heading>
        <Text base primitive700>
          {el?.info}
        </Text>
        <Button
          btnPrimary

          disabled={!sufficientBalance}
          wFull
          className={classes.button}
          onClick={() => {
            if (!sufficientBalance) {
              alert("Insufficient Balance")
            }
            setShowCreateInvestmentModal(true)
          }}
        >
          {
            !sufficientBalance ? "Insufficient Balance" : "Invest Now"
          }
        </Button>
        <div className={classes.depositInfo}>
          {/*  */}
          <div className={classes.spaceBetween} >
            <Text className={classes.key} primitive700 base>
              Min. Deposit
            </Text>
            <Text
              // className={clsx(
              //   classes.value,
              //   info.value.toLowerCase() === "completed"
              //     ? classes.completed
              //     : info.value.toLowerCase() === "pending"
              //       ? classes.pending
              //       : info.value.toLowerCase() === "active"
              //         ? classes.active
              //         : info.value.toLowerCase() === "failed"
              //           ? classes.failed
              //           : ""
              // )}
              primitive800
              base
              semiBold
            >
              {parseInt(el?.minimum_amount)} USD
            </Text>
          </div>
          {/*  */}

          <div className={classes.spaceBetween} >
            <Text className={classes.key} primitive700 base>
              Max. Deposit
            </Text>
            <Text
              // className={clsx(
              //   classes.value,
              //   info.value.toLowerCase() === "completed"
              //     ? classes.completed
              //     : info.value.toLowerCase() === "pending"
              //       ? classes.pending
              //       : info.value.toLowerCase() === "active"
              //         ? classes.active
              //         : info.value.toLowerCase() === "failed"
              //           ? classes.failed
              //           : ""
              // )}
              primitive800
              base
              semiBold
            >
              {parseInt(el?.maximum_amount)} USD
            </Text>
          </div>
          {/*  */}
          <div className={classes.spaceBetween} >
            <Text className={classes.key} primitive700 base>
              Duration
            </Text>
            <Text
              // className={clsx(
              //   classes.value,
              //   info.value.toLowerCase() === "completed"
              //     ? classes.completed
              //     : info.value.toLowerCase() === "pending"
              //       ? classes.pending
              //       : info.value.toLowerCase() === "active"
              //         ? classes.active
              //         : info.value.toLowerCase() === "failed"
              //           ? classes.failed
              //           : ""
              // )}
              primitive800
              base
              semiBold
            >
              {el?.duration}  {
                el.duration_in.toLowerCase() == "days" && el.duration == 1 ? "day" : el.duration_in.toLowerCase() == "days" ? "days" : el.duration_in.toLowerCase() == "weeks" && el.duration == 1 ? "week" : el.duration_in.toLowerCase() == "weeks" ? "weeks" : el.duration_in.toLowerCase() == "months" && el.duration == 1 ? "month" : el.duration_in.toLowerCase() == "months" ? "months" : ""
              }
            </Text>
          </div>
          {/*  */}

          <div className={classes.spaceBetween} >
            <Text className={classes.key} primitive700 base>
              Status
            </Text>
            <Text
              className={clsx(
                classes.value,
                el?.status.toLowerCase() === "completed"
                  ? classes.completed
                  : el?.status.toLowerCase() === "pending"
                    ? classes.pending
                    : el?.status.toLowerCase() === "active"
                      ? classes.active
                      : el?.status.toLowerCase() === "failed"
                        ? classes.failed
                        : ""
              )}
              primitive800
              base
              semiBold
            >
              {el?.status}
            </Text>
          </div>
          {/*  */}
        </div>
      </div>
      <CreateInvestmentsModal
        amount={amount}
        setAmount={setAmount}
        isActive={showCreateInvestmentModal}
        position={el}
        onClick={async () => {
          try {
            // setShowDepositModal(true);
            let { data, status } = await open_position({
              plan_id: el.id,
              amount: Number(amount),
            });
            console.log("data 183 ", data);
            if (status !== 200) {
              toast.error(data);
              return;
            }

            console.log("data", data);

            setNewInvestment(data);
            if (data?.id) {
              setShowTransactionDetailsModal(true);
            }
          } catch (e) {
            console.log("wdwdwdw", e);
            toast.error(e);
          }
        }}
        onClose={() => setShowCreateInvestmentModal(false)}
      />{" "}

      <TransactionDetailsModal
        investment={newInvestment}
        isActive={showTransactionDetailsModal}
        onClick={() => { }}
        onClose={() => {
          window.location.href = "/dashboard";
          setShowTransactionDetailsModal(false);
        }}
      />
      <DepositModal
        isActive={showDepositModal}
        onClick={() => {
          setShowGenerateAddressModal(true);
        }}
        onClose={() => setShowDepositModal(false)}
      />
      <GenerateAddressModal
        isActive={showGenerateAddressModal}
        onClick={() => {
          setShowTransactionDetailsModal(true);
        }}
        onClose={() => setShowGenerateAddressModal(false)}
      />{" "}

    </>
  );
};

export default SinglePlan;
