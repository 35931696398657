import React, { useState } from "react";
import classes from "./WithdrawModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";

import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";

import clsx from "clsx";
import { handleKeyDown } from "hooks";
import AssetDropdown from "../AssetDropdown/AssetDropdown";
import { Link } from "react-router-dom";

const WithdrawModal = ({
  isActive, userData,

  onClose,
  onClick,
}) => {
  const [showAssetDropdown, setShowAssetDropdown] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");

  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const [address, setAddress] = useState("");

  const [amount, setAmount] = useState("");

  return (
    <>
      <Modal isActive={isActive} onClose={onClose} heading="Withdraw">
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div>
              <Text sm primitive600>
                Available Balance
              </Text>
              <Heading xl2 className={classes.price}>
                {userData?.balance}{" "}
                <Text sm semiBold primitive600>
                  USD
                </Text>
              </Heading>
            </div>
            <Text semiBold sm className={classes.howToWithdraw}>
              <Link className={classes.link}> How to withdraw?</Link>
            </Text>
          </div>
          <div className={classes.selectContainer}>
            <Heading base primitive600 regular className={classes.label}>
              Select Asset
            </Heading>
            <AssetDropdown
              isActive={showAssetDropdown}
              setIsActive={setShowAssetDropdown}
              selectedValue={selectedAsset}
              onSelect={(val) => setSelectedAsset(val)}
            />{" "}

            {/* Wallet Address */}
            <Heading base primitive600 regular className={classes.label}>
              Wallet Address
            </Heading>
            <Input
              value={address}
              setValue={setAddress}
              className={classes.input}
            />
            {/* Withdraw Amount */}
            <Heading base primitive600 regular className={classes.label}>
              Withdraw Amount
            </Heading>
            <div className={classes.amountInputContainer}>
              <div className={classes.amountInputWrapper}>
                <Input
                  value={amount}
                  setValue={setAmount}
                  type="number"
                  onKeyDown={handleKeyDown}
                  className={clsx(classes.input, classes.amountInput)}
                  placeholder="0.00"
                />
                <Text sm primitive400 className={classes.currency}>
                  USD
                </Text>
              </div>
              {amount && (
                <Text primitive600 sm className={classes.info}>
                  Platform Fee
                  <span className={classes.fee}>1.00 USD</span>
                </Text>
              )}
              {!amount && (
                <Text primitive600 sm className={classes.info}>
                  Enter amount to calculate fee
                </Text>
              )}
            </div>{" "}
          </div>

          <div className={classes.buttonContainer}>
            <Button transparent onClick={onClose} className={classes.button}>
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                onClose();
                onClick();
              }}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WithdrawModal;
