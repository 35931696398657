import { countries } from "common/constants";
import React, { useEffect, useState } from "react";
import classes from "./Security.module.css";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import TwoFactorItem from "./TwoFactorItem";
import VerifyIdentity from "components/Athentication/VerifyIdentity/VerifyIdentity";
import AddNew from "./AddNew/AddNew";
import Verify from "components/Athentication/VerifyIdentity/Verify/Verify";
import Successfull from "components/common/Successfull/Successfull";
import Buttons from "./Buttons/Buttons";
import TopHeader from "./TopHeader/TopHeader";
import UserInfo from "./UserInfo";
import SignInInfo from "./SignInInfo";
import clsx from "clsx";
import { Text } from "components/common";

const Security = ({
  step,
  setStep,
  selectedVerificationMethod,
  setSelectedVerificationMethod,
}) => {
  const [type, setType] = useState("");

  const [verifyNow, setVerifyNow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [twoFAStatus, setTwoFAStatus] = useState({
    email: "imran@da.com",
    phone: "",
    authenticatorApp: "On",
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(countries[0]);

  const [userInfo, setUserInfo] = useState({
    email: "imran@gmail.com",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [signInInfo, setSignInInfo] = useState({
    googleId: "imran@gmail.com",
    appleID: "",
  });

  // Common function for updating state and step
  const handleStatus = (type) => {
    setType(type);

    setStep((prev) => prev + 1);
  };

  // Common 2FA handling logic
  const handle2FAStatus = (method, field) => {
    if (!twoFAStatus[field]) {
    } else {
      setShowSuccess(true);
      // setTwoFAStatus((prev) => ({
      //   ...prev,
      //   [field]: "",
      // }));
      selectedVerificationMethod !== 3 && setSelectedVerificationMethod(0);
    }
  };

  const handleVerifyIdenty = () => {
    setStep((prev) => prev + 1);

    switch (type) {
      case "2FAEmail":
        handle2FAStatus("Email", "email");

        break;
      case "Phone":
        handle2FAStatus("Phone", "phone");
        break;
      case "Authenticator App":
        handle2FAStatus("Authenticator App", "authenticatorApp");
        break;
      case "Change Email":
        break;
      case "Change Password":
        break;
      case "Google ID":
      case "Apple ID":
        setShowSuccess(true);
        break;
      default:
        break;
    }
  };

  const handleVerify = () => {
    setVerifyNow(false);
    setShowSuccess(true);
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    // Initialize 2FA state
    setTwoFAStatus({
      email: twoFAStatus.email,
      phone: twoFAStatus.phone,
      authenticatorApp: twoFAStatus.authenticatorApp,
    });
  }, []);

  ///back and cancel button showing logic

  const handleBack = () => {
    setStep((prev) => prev - 1);
    if (verifyNow) setVerifyNow(false);
  };
  const handleCancel = () => {
    setStep(0);
  };
  const showButtons =
    !showSuccess && (selectedVerificationMethod === 3 ? step > 2 : step > 3);

  // get success heading and info

  return (
    <>
      {step > 0 && (
        <TopHeader
          showSuccess={showSuccess}
          type={type}
          step={step}
          twoFAStatus={twoFAStatus}
          selectedVerificationMethod={selectedVerificationMethod}
        />
      )}
      {step === 0 && (
        <>
          <div className={classes.container2FA}>
            <div className={classes.header}>
              <Text semiBold base>
                2FA Security
              </Text>
              <Text
                sm
                font600
                className={clsx(
                  twoFAStatus.email || twoFAStatus.phone
                    ? classes.on
                    : classes.off
                )}
              >
                {twoFAStatus.email || twoFAStatus.phone ? "On" : "Off"}
              </Text>
            </div>
            <TwoFactorItem
              label="Email"
              value={twoFAStatus.email}
              onClick={() => handleStatus("2FAEmail")}
            />
            <TwoFactorItem
              label="Phone"
              value={twoFAStatus.phone}
              onClick={() => handleStatus("Phone")}
            />
            <TwoFactorItem
              label="Authenticator App"
              value={twoFAStatus.authenticatorApp}
              onClick={() => handleStatus("Authenticator App")}
            />
          </div>
          <div className={classes.userInfo}>
            <UserInfo
              label="Email"
              value={userInfo.email}
              onClick={() => handleStatus("Change Email")}
            />
            <UserInfo
              label="Password"
              value={userInfo.newPassword}
              onClick={() => handleStatus("Change Password")}
            />
          </div>
          <div className={classes.signInInfo}>
            <SignInInfo
              logo={<FcGoogle className={classes.google} />}
              label="Sign-in with"
              value={signInInfo.googleId}
              onClick={() => handleStatus("Google ID")}
            />
            <SignInInfo
              logo={
                <FaApple className={clsx(classes.apple, classes.appleLogo)} />
              }
              label="Sign-in with"
              value={signInInfo.appleID}
              onClick={() => handleStatus("Apple ID")}
            />
          </div>
        </>
      )}

      <div className={classes.verifyIdentity}>
        {step > 0 &&
          (selectedVerificationMethod === 3 ? step < 3 : step < 4) && (
            <VerifyIdentity
              type2
              xl2
              selectedMethod={selectedVerificationMethod}
              setSelectedMethod={setSelectedVerificationMethod}
              onVerify={handleVerifyIdenty}
              step={step}
              setStep={setStep}
              setShowSuccess={setShowSuccess}
            />
          )}
        {(selectedVerificationMethod === 3 ? step === 3 : step === 4) &&
          (type === "Email"
            ? !twoFAStatus.email
            : type === "Phone"
            ? !twoFAStatus.phone
            : type === "Authenticator App"
            ? !twoFAStatus.authenticatorApp
            : type === "Change Email"
            ? true
            : type === "Change Password"
            ? true
            : false) && (
            <AddNew
              type={type}
              email={
                type === "2FAEmail"
                  ? twoFAStatus.email
                  : type === "Change Email"
                  ? userInfo.email
                  : ""
              }
              setEmail={(email) => {
                if (type === "2FAEmail") {
                  setTwoFAStatus((prev) => ({ ...prev, email }));
                }
                if (type === "Change Email") {
                  setUserInfo((prev) => ({ ...prev, email }));
                }
              }}
              phone={twoFAStatus.phone}
              setPhone={(phone) =>
                setTwoFAStatus((prev) => ({ ...prev, phone }))
              }
              selectedCountryCode={selectedCountryCode}
              setSelectedCountryCode={setSelectedCountryCode}
              newPassword={userInfo.newPassword}
              setNewPassword={(newPassword) =>
                setUserInfo((prev) => ({ ...prev, newPassword }))
              }
              confirmNewPassword={userInfo.confirmNewPassword}
              setConfirmNewPassword={(confirmNewPassword) =>
                setUserInfo((prev) => ({ ...prev, confirmNewPassword }))
              }
              onConitnue={() => {
                setStep((prev) => prev + 1);

                setVerifyNow(true);

                if (type === "Change Password") {
                  setVerifyNow(false);
                  setShowSuccess(true);
                }
              }}
            />
          )}

        {verifyNow && (
          <Verify
            xl2
            heading={
              type === "2FAEmail"
                ? "Verify Email"
                : type === "Phone"
                ? "Verify Phone"
                : type === "Authenticator App"
                ? "Verify Authenticator App"
                : type === "Change Email"
                ? "Verify Email"
                : ""
            }
            info={
              type === "2FAEmail"
                ? "We will send you a message with 6 digit verification code to your Email."
                : type === "Phone"
                ? "We will send you a message with 6 digit verification code to your phone."
                : type === "Authenticator App"
                ? "Verify Authenticator App"
                : type === "Change Email"
                ? "We will send you a message with 6 digit verification code to your Email."
                : ""
            }
            onVerify={handleVerify}
            noResend={type === "Authenticator App"}
            codeSentOn={
              type === "2FAEmail"
                ? twoFAStatus.email
                : type === "Phone"
                ? twoFAStatus.phone
                : "to your device"
            }
          />
        )}

        {showSuccess && (
          <Successfull
            type={type}
            twoFAStatus={twoFAStatus}
            onBack={() => {
              setShowSuccess(false);
              setStep(0);
              setSelectedVerificationMethod(0);
            }}
          />
        )}

        {showButtons && (
          <Buttons handleCancel={handleCancel} handleBack={handleBack} />
        )}
      </div>
    </>
  );
};

export default Security;
