import React, { useState } from "react";
import classes from "./ForgotPassword.module.css";
import clsx from "clsx";
import InfoContainer from "components/Athentication/ForgotPassword/InfoContainer/InfoContainer";
import Form from "components/Athentication/ForgotPassword/Form/Form";

const ForgotPassword = () => {
  // step === 1 verification method
  // step ===2 verify identity
  // step === 3 change password
  // step === 4 password change succesfull
  const [step, setStep] = useState(1);
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <InfoContainer step={step} />
        <Form step={step} setStep={setStep} />
      </div>
    </section>
  );
};

export default ForgotPassword;
