import React, { useState } from "react";
import { authenticatorIcon, emailIcon, smsIcon } from "images";
import Header from "components/Athentication/Header/Header";
import classes from "./VerificationMethod.module.css";
import { Text } from "components/common";
import { IoIosArrowForward } from "react-icons/io";

const VerificationMethod = ({ setStep, setSelectedMethod, xl2 }) => {
  const methods = [
    { icon: emailIcon, title: "Verify via Email" },
    { icon: smsIcon, title: "Verify via SMS" },
    { icon: authenticatorIcon, title: "Verify via Authenticator App" },
  ];
  return (
    <>
      <Header
        xl2={xl2}
        heading="Verification Method"
        info="Select how you’d like to verify your identity to securely access your account."
      />
      <div className={classes.methods}>
        {methods.map((method, i) => (
          <button
            className={classes.method}
            key={i}
            onClick={() => {
              setStep && setStep((prev) => prev + 1);
              setSelectedMethod && setSelectedMethod(i + 1);
            }}
          >
            <img src={method.icon} alt="#" className={classes.icon} />
            <Text semiBold lg primitive800 className={classes.methodTitle}>
              {method.title}
            </Text>
            <IoIosArrowForward className={classes.arrow} />
          </button>
        ))}
      </div>
    </>
  );
};

export default VerificationMethod;
