import React from "react";
import classes from "./OurSpecialties.module.css";
import clsx from "clsx";
import { FaRegCircleCheck } from "react-icons/fa6";

import { specialistImg } from "images";
import { Button, Heading, SectionHeading, Text } from "components/common";

const OurSpecialties = ({ homepage_contents }) => {
  const { our_specialties } = homepage_contents;
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.infoContainer}>
          <div className={classes.header}>
            <SectionHeading>{our_specialties.heading}</SectionHeading>
            <Heading xl5>{our_specialties.title}</Heading>
            <Text xl2 primitive800 semiBold>
              {our_specialties.subtitle}
            </Text>
            <Button btnPrimary className={classes.button}>
              Learn More
            </Button>
          </div>
          <div className={classes.features}>
            {our_specialties?.features.map((el, i) => (
              <div className={classes.feature} key={i}>
                <FaRegCircleCheck className={classes.checkmark} />
                <div className={classes.content}>
                  <Heading xl2>{el.title}</Heading>
                  <Text primitive800 xl>
                    {el.info}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.imgContainer}>
          <img src={specialistImg} alt="#" className={classes.img} />
        </div>
      </div>
    </section>
  );
};

export default OurSpecialties;
