import React from "react";
import classes from "./RiskDisclosurePage.module.css";
import clsx from "clsx";
import { Heading, SectionHeading, Text } from "components/common";

const HowToStartPage = ({ frontendInfo }) => {
    return (
        <section className={classes.wrapper}>
            <div className={clsx(classes.container, "container")}>
                {/* Header Section */}
                <div className={classes.header}>
                    <SectionHeading>HOW TO START</SectionHeading>
                    <Heading xl6 primitive950 className={classes.heading}>
                        Start Your Journey in Just a Few Simple Steps
                    </Heading>
                    <Text primitive800 xl className={classes.info}>
                        Getting started with {frontendInfo?.site_name} is easy. Follow these simple steps to begin your investment journey and start seeing the benefits.
                    </Text>
                </div>

                {/* Step 1: Create an Account Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.createAccount}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Step 1: Create an Account
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            To begin, simply sign up for an account with {frontendInfo?.site_name}. Provide the necessary details, such as your name, email address, and a secure password. Verify your email, and you will be ready to move on to the next step.
                        </Text>
                    </div>
                </div>

                {/* Step 2: Fund Your Account and Choose a Plan Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.fundAccount}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Step 2: Fund Your Account and Choose a Plan
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            Once your account is set up, the next step is to fund it. You can add funds using a variety of methods, including bank transfers or credit/debit cards. After funding your account, explore the available investment plans that best suit your financial goals and risk tolerance.
                        </Text>
                    </div>
                </div>

                {/* Step 3: Get Profits Section */}
                <div className={classes.sectionWrapper}>
                    <div className={classes.getProfits}>
                        <Heading xl5 primitive950 className={classes.subheading}>
                            Step 3: Get Profits
                        </Heading>
                        <Text primitive800 lg className={classes.text}>
                            With your account funded and your chosen plan in place, your investments will start working for you. Monitor your progress through your dashboard and watch as your investments grow, delivering potential profits over time. Remember, the key to successful investing is patience and consistency.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowToStartPage;
