import React from "react";
import classes from "./Buttons.module.css";
import { Button } from "components/common";
import clsx from "clsx";

const Buttons = ({ handleCancel, handleBack }) => {
  // Handle Cancel

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          className={clsx(classes.cancelButton, classes.button)}
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          className={clsx(classes.backButton, classes.button)}
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default Buttons;
