import React, { useState } from "react";
import classes from "./Verify.module.css";

import Header from "components/Athentication/Header/Header";
import VerificationCode from "../../VerificationCode/VerificationCode";

const Verify = ({
  codeSentOn,
  selectedMethod,
  setStep,
  xl2,
  onVerify,
  noHeading,
  noResend,
  heading,
  info,
}) => {
  const [OTP, setOTP] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);

  return (
    <div className={classes.wrapper}>
      {!noHeading && (
        <Header
          xl2={xl2}
          heading={
            selectedMethod === 1
              ? "Verify your Email"
              : selectedMethod === 2
              ? "Verify your Phone"
              : selectedMethod === 3
              ? "Verify Authenticator App"
              : heading
          }
          info={
            selectedMethod === 1
              ? "Enter the verification code we sent to "
              : selectedMethod === 2
              ? "Enter the verification code we sent to "
              : selectedMethod === 3
              ? "Enter the verification code from your authenticator app"
              : info
          }
          codeSentOn={codeSentOn && codeSentOn}
        />
      )}

      <VerificationCode
        otp={OTP}
        setOTP={setOTP}
        setOtpInvalid={setOtpInvalid}
        noResend={selectedMethod === 3 || noResend}
        setStep={setStep}
        onVerify={onVerify}
      />
    </div>
  );
};

export default Verify;
