import React, { useRef, useState } from "react";
import classes from "./AddNew.module.css";
import Header from "components/Athentication/Header/Header";
import { Button, Input } from "components/common";
import PhoneNumberDropdown from "components/common/PhoneNumberDropdown/PhoneNumberDropdown";

import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { handleKeyDown } from "hooks";
import ScanQrCode from "../ScanQrCode/ScanQrCode";

const AddNew = ({
  type,
  email,
  phone,
  setEmail,
  setPhone,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  selectedCountryCode,
  setSelectedCountryCode,
  setStep,
  xl2,
  onConitnue,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const dropdownRef = useRef(null);

  return (
    <>
      <Header
        xl2
        heading={
          type === "2FAEmail"
            ? "Enter your New email"
            : type === "Change Email"
            ? "Change Your email"
            : type === "Phone"
            ? "Enter New Phone"
            : type === "Change Password"
            ? "Change Password"
            : type === "Authenticator App"
            ? " Scan the QR Code"
            : ""
        }
        info={
          type === "2FAEmail"
            ? "We will send you a message with 6 digit verification code to your email."
            : type === "Change Email"
            ? "We will send you a message with 6 digit verification code to your Email."
            : type === "Phone"
            ? "We will send you a message with 6 digit verification code to your Authenticator App"
            : type === "Change Password"
            ? "Enter your new password. Ensure your password is strong enough."
            : type === "Authenticator App"
            ? "Open your google authenticator app and scan the qr code to add this 2FA security."
            : ""
        }
      />

      <div className={classes.inputWrapper}>
        {(type === "2FAEmail" || type === "Change Email") && (
          <Input
            value={email}
            setValue={setEmail}
            placeholder="Email address"
          />
        )}
        {type === "Phone" && (
          <div className={classes.phoneNumberContainer}>
            <div ref={dropdownRef}>
              <PhoneNumberDropdown
                isActive={isDropdownActive}
                selectedValue={selectedCountryCode}
                onSelect={(val) => {
                  setSelectedCountryCode(val);
                  setIsDropdownActive(false);
                }}
              >
                <div
                  className={classes.dropdownItem}
                  onClick={() => {
                    setIsDropdownActive((prev) => !prev);
                  }}
                >
                  <span className={classes.code}>
                    {selectedCountryCode.code}
                  </span>
                  {isDropdownActive ? (
                    <FaAngleUp className={classes.arrow} />
                  ) : (
                    <FaAngleDown className={classes.arrow} />
                  )}
                </div>
              </PhoneNumberDropdown>
            </div>
            <Input
              className={classes.input}
              onKeyDown={handleKeyDown}
              type="number"
              placeholder="Phone number"
              value={phone}
              setValue={setPhone}
            />
          </div>
        )}

        {type === "Change Password" && (
          <>
            <Input
              type="password"
              value={newPassword}
              setValue={setNewPassword}
              placeholder="New Password"
            />{" "}
            <Input
              type="password"
              value={confirmNewPassword}
              setValue={setConfirmNewPassword}
              placeholder="Confirm new password"
            />
          </>
        )}

        {type === "Authenticator App" && (
          <>
            <ScanQrCode onContinue={onConitnue} />
          </>
        )}

        {type !== "Authenticator App" && (
          <Button
            wFull
            onClick={() => {
              onConitnue && onConitnue();
            }}
          >
            {type === "Change Password" ? "Save Password" : "Continue"}
          </Button>
        )}
      </div>
    </>
  );
};

export default AddNew;
