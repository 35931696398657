import React, { useState } from "react";
import classes from "./DepositModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";

import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";

import clsx from "clsx";
import { handleKeyDown } from "hooks";
import AssetDropdown from "../AssetDropdown/AssetDropdown";

const DepositModal = ({
  isActive,

  onClose,
  onClick,
}) => {
  const [showAssetDropdown, setShowAssetDropdown] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");

  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const [amount, setAmount] = useState("");

  return (
    <>
      <Modal isActive={isActive} onClose={onClose} heading="Deposit">
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Text primitive600 base>
              <span className={classes.note}>Note:</span> Your investment data
              will be displayed on your account dashboard after you complete the
              deposit.
            </Text>
          </div>
          <div className={classes.selectContainer}>
            <Heading base primitive600 regular className={classes.label}>
              Select Asset
            </Heading>
            <AssetDropdown
              isActive={showAssetDropdown}
              setIsActive={setShowAssetDropdown}
              selectedValue={selectedAsset}
              onSelect={(val) => setSelectedAsset(val)}
            />{" "}
            {/* <Heading base primitive600 regular className={classes.label}>
              Select Network
            </Heading> */}
            {/* <div className={classes.networkContainer}>
              <NetworkDropdown
                className={!selectedAsset && classes.disabled}
                isActive={selectedAsset && showNetworkDropdown}
                setIsActive={setShowNetworkDropdown}
                selectedValue={selectedNetwork}
                onSelect={(val) => setSelectedNetwork(val)}
              />
              {!selectedAsset && (
                <Text primitive600 sm className={classes.info}>
                  You need to select wallet first.
                </Text>
              )}
            </div>{" "} */}



            {/* Deposit Amount */}
            <Heading base primitive600 regular className={classes.label}>
              Deposit Amount
            </Heading>
            <div className={classes.amountInputContainer}>
              <div className={classes.amountInputWrapper}>
                <Input
                  value={amount}
                  setValue={setAmount}
                  type="number"
                  onKeyDown={handleKeyDown}
                  className={clsx(classes.input, classes.amountInput)}
                  placeholder="0.00"
                />
                <Text sm primitive400 className={classes.currency}>
                  USD
                </Text>
              </div>
              {/* {amount && (
                <Text primitive600 sm className={classes.info}>
                  Platform Fee
                  <span className={classes.fee}>0.00 USD</span>
                </Text>
              )}
              {!amount && (
                <Text primitive600 sm className={classes.info}>
                  Enter amount to calculate fee
                </Text>
              )} */}
            </div>{" "}
          </div>

          <div className={classes.buttonContainer}>
            <Button transparent onClick={onClose} className={classes.button}>
              Cancel
            </Button>{" "}
            <Button
              onClick={async () => {
                let body = {
                  amount: Number(amount),
                  currency_id: selectedAsset.now_payments_id,
                }

                await onClick(body);
                onClose();
              }}
            >
              Generate Address
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DepositModal;
