import React, { useState, useMemo, useEffect } from "react";
import classes from "./InvestmentChart.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Dropdown, Heading, Text } from "components/common";

const InvestmentChart = ({ positions }) => {
  const [allData, setAllData] = useState([]);
  const dropdownItems = ["Last 30 days", "Last 6 Month", "Last 1 year"];
  const [selectedValue, setSelectedValue] = useState("Last 30 days");
  const [showDropdown, setShowDropdown] = useState(false);

  // Function to calculate data and set it in state based on positions
  function calculateData() {
    const parsedData = positions.map((position) => {
      return {
        date: new Date(position.created_at_timestamp * 1000).toLocaleDateString(
          "en-US",
          {
            month: "short",
            day: "numeric",
            year: "numeric",
          }
        ),
        invest: position.capital || 0, // Default to 0 if no capital
        profit: position.profit || 0, // Default to 0 if no profit
      };
    });
    setAllData(parsedData);
  }

  // Parse date correctly
  const parseDate = (dateStr) => {
    return new Date(dateStr); // Simplified date parsing
  };

  // Calculate the totals for investment
  const calculateTotals = (data) => {
    return data.reduce(
      (totals, entry) => {
        totals.invest += Number(entry.invest);
        totals.profit += Number(entry.profit);
        return totals;
      },
      { invest: 0, profit: 0 }
    );
  };

  // Filter data based on the selected dropdown item
  const filteredData = useMemo(() => {
    const today = new Date();
    const dateRanges = {
      "Last 30 days": 30,
      "Last 6 Month": 180,
      "Last 1 year": 365,
    };
    const daysAgo = dateRanges[selectedValue];
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - daysAgo);

    return allData.filter((entry) => {
      const entryDate = parseDate(entry.date);
      return entryDate >= cutoffDate;
    });
  }, [selectedValue, allData]);

  // Totals for the legend
  const totals = calculateTotals(filteredData);

  // Custom legend
  const CustomLegend = () => {
    return (
      <div className={classes.customLegend}>
        <div className={classes.legendItem}>
          <span
            className={classes.legendColor}
            style={{ backgroundColor: "#4669f5" }}
          ></span>
          <Text sm semiBold primitive600 textLeft>
            Investment
          </Text>
          <Text sm semiBold primitive950 textLeft>
            {Number(totals.invest).toFixed(2).toLocaleString()}
          </Text>
        </div>
        {/* <div className={classes.legendItem}>
          {" "}
          <span
            className={classes.legendColor}
            style={{ backgroundColor: "#22c55e" }}
          ></span>{" "}
          <Text sm semiBold primitive600 textLeft>
            Profit
          </Text>
          <Text sm semiBold primitive950 textLeft>
            {Number(totals.profit).toFixed(2).toLocaleString()}
          </Text>
        </div> */}
      </div>
    );
  };

  // Load initial data
  useEffect(() => {
    calculateData();
  }, [positions]);

  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg semiBold>
          Investments Chart
        </Heading>
        {/* <Dropdown
          className={classes.dropdown}
          items={dropdownItems}
          isActive={showDropdown}
          setIsActive={setShowDropdown}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          onSelect={(val) => setSelectedValue(val)}
        /> */}
      </div>
      <div className={classes.chartContainer}>
        <CustomLegend />
        <ResponsiveContainer width="100%" height={172}>
          <BarChart
            data={filteredData}
            margin={{ top: 20, right: 0, left: -40, bottom: -10 }}
          >
            <CartesianGrid stroke="#F6F6F9" />
            <XAxis
              dataKey="date"
              tickFormatter={(date, index) =>
                index % 3 === 0
                  ? new Date(date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })
                  : ""
              }
              tick={{ fill: "#9495BF", fontSize: 10 }}
              axisLine={{ stroke: "#F6F6F9" }}
            />
            <YAxis
              domain={[0, "auto"]} // Auto scale but starts from 0
              tickFormatter={(value) =>
                value >= 1000 ? `${(value / 1000).toFixed(0)}k` : value
              } // Format large numbers in 'k' format
              tick={{ fill: "#9495BF", fontSize: 10 }}
              axisLine={{ stroke: "#F6F6F9" }}
              ticks={Array.from(
                {
                  length: Math.ceil(
                    Math.max(...filteredData.map((d) => d.invest + d.profit)) /
                    1000
                  ),
                },
                (_, i) => i * 1000
              )}
            />
            <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
            <Bar
              cursor="pointer"
              dataKey="invest"
              stackId="a"
              fill="#4669f5"
              radius={[0, 0, 0, 0]}
              barSize={50}
              isAnimationActive={false}
            />
            <Bar
              cursor="pointer"
              dataKey="profit"
              stackId="a"
              fill="#22c55e"
              radius={[2, 2, 0, 0]}
              barSize={50}
              isAnimationActive={false}
              style={{ pointerEvents: "none" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default InvestmentChart;

const CustomTooltip = ({ active, payload }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0].value !== null &&
    payload[1]?.value !== null
  ) {
    return (
      <div className={classes.tooltip}>
        <Text xs semiBold>{`${payload[0].payload.date}`}</Text>
        <Text xs>Investment: {payload[0].value.toLocaleString()}</Text>
        <Text xs>Profit: {payload[1]?.value?.toLocaleString()}</Text>
      </div>
    );
  }
  return null;
};
