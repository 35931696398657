import React, { useState } from "react";
import classes from "./Signup.module.css";
import clsx from "clsx";
import InfoContainer from "../../../components/Athentication/Signup/InfoContainer/InfoContainer";
import SignupForm from "components/Athentication/Signup/SignupForm/SignupForm";
import VerifyYourEmail from "components/Athentication/Signup/VerifyYourEmail/VerifyYourEmail";

const Signup = ({ frontendInfo }) => {
  const [step, setStep] = useState(1);
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <InfoContainer frontendInfo={frontendInfo} />
        {step === 1 && <SignupForm frontendInfo={frontendInfo} setStep={setStep} />}
        {step === 2 && <VerifyYourEmail />}
      </div>
    </section>
  );
};

export default Signup;
