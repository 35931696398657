import React from "react";
import classes from "./Successfull.module.css";

import { Button, Heading, Text } from "components/common";
import { successImg } from "images";
const Successfull = ({ type, twoFAStatus, onBack }) => {
  const getHeading = (type, twoFAStatus) => {
    if (type === "2FAEmail")
      return twoFAStatus.email ? "Your Email Deleted" : "Your Email Added";
    if (type === "Phone")
      return twoFAStatus.phone ? "Your Phone Deleted" : "SMS 2FA Added";
    if (type === "Authenticator App")
      return twoFAStatus.authenticatorApp
        ? "Authenticator Deleted"
        : "Authenticator App Added";
    if (type === "Change Email") return "Email Changed";
    if (type === "Change Password") return "Password Changed";
    if (type === "Google ID") return "Google ID Added";
    if (type === "Apple ID") return "Apple ID Added";
    return "";
  };

  const getInfo = (type, twoFAStatus) => {
    if (type === "2FAEmail")
      return twoFAStatus.email ? "Your Email Deleted" : "Your Email Added";
    if (type === "Phone")
      return twoFAStatus.phone
        ? "Your Phone Deleted"
        : "You have successfully added SMS 2FA security. Now you can use this for identity verification.";
    if (type === "Authenticator App")
      return twoFAStatus.authenticatorApp
        ? "Your Authenticator Deleted"
        : "You have successfully added Authenticator 2FA security. Now you can use this for your identity verification.";
    if (type === "Change Email")
      return "You have successfully changed your email. Now you can use your new email for login.";
    if (type === "Change Password")
      return "Your password is successfully changed. You can login to your account with the new password.";
    if (type === "Google ID") return "Google ID Added";
    if (type === "Apple ID") return "Apple ID Added";
    return "";
  };
  return (
    <div className={classes.wrapper}>
      <img src={successImg} alt="#" className={classes.img} />
      <div className={classes.infoContainer}>
        <Heading xl2 semiBold textCenter>
          {getHeading(type, twoFAStatus)}
        </Heading>
        <Text base primitive700 textCenter>
          {getInfo(type, twoFAStatus)}
        </Text>
      </div>
      <Button onClick={onBack} base>
        Back
      </Button>
    </div>
  );
};

export default Successfull;
