import React, { useState } from "react";
import classes from "./LoginForm.module.css";

import { Link, useNavigate } from "react-router-dom";
import { Button, Heading, Input, Text } from "components/common";

import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import clsx from "clsx";
import { login_user } from "helpers/api/apis";

const LoginForm = ({ setIsLoggedIn, setReloadState }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  async function process_login() {
    if (!email || !password) {
      return;
    }
    let { data } = await login_user({ email, password }, setIsLoggedIn);
    if (data.token) {
      setReloadState((prev) => prev + 1);
      navigate("/dashboard");
    }
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <Heading xl3 primitive950 semiBold textCenter>
          Login
        </Heading>
        <Text lg primitive600 textCenter className={classes.needAnAccount}>
          Need an account?
          <Link className={classes.link} to="/sign-up">
            Sign up
          </Link>
        </Text>
      </div>

      <div className={classes.inputWrapper}>
        <Input
          type="email"
          value={email}
          setValue={setEmail}
          placeholder="Email "
        />
        <Input
          type="password"
          value={password}
          setValue={setPassword}
          placeholder="Password"
        />

        <div className={classes.buttonContainer}>
          <Button wFull base onClick={(e) => {
            e.preventDefault();
            process_login();
          }}>
            Login
          </Button>
          <Button
            transparent
            to="/forgot-password"
            className={classes.button}
            base
          >
            Forgot Password?
          </Button>
        </div>
      </div>
      <Text primitive600 sm className={classes.or} textCenter>
        or
      </Text>
      <div className={classes.buttonContainer}>
        <Button primitive50 base>
          <FcGoogle className={classes.logo} /> Use Google
        </Button>{" "}
        <Button primitive50 base>
          <FaApple className={clsx(classes.logo, classes.appleLogo)} /> Use
          Apple
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
