import React, { useState } from "react";
import classes from "./CreateInvestmentsModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Input, Text } from "components/common";

import clsx from "clsx";
import Checkbox from "components/common/CheckBox/CheckBox";
import { handleKeyDown } from "hooks";

const CreateInvestmentsModal = ({ setAmount, amount, isActive, position, onClose, onClick }) => {
  const [agreeToPolicy, setAgreeToPolicy] = useState();
  const depositInfo = [
    {
      key: "Min. Deposit",
      value: position?.minimum_amount,
    },
    {
      key: "Max. Deposit",
      value: position?.maximum_amount,
    },
    {
      key: "Duration",
      // value: position?.duration + " " + {
      //   el.duration_in.toLowerCase() == "days" && el.duration == 1 ? "day" : el.duration_in.toLowerCase() == "days" ? "days" : el.duration_in.toLowerCase() == "weeks" && el.duration == 1 ? "week" : el.duration_in.toLowerCase() == "weeks" ? "weeks" : el.duration_in.toLowerCase() == "months" && el.duration == 1 ? "month" : el.duration_in.toLowerCase() == "months" ? "months" : ""
      // }
      value: position?.duration + " " +
        position.duration_in.toLowerCase() === "days" && position.duration === 1 ? "day"
        : position.duration_in.toLowerCase() === "days" ? "days"
          : position.duration_in.toLowerCase() === "weeks" && position.duration === 1 ? "week"
            : position.duration_in.toLowerCase() === "weeks" ? "weeks"
              : position.duration_in.toLowerCase() === "months" && position.duration === 1 ? "month"
                : position.duration_in.toLowerCase() === "months" ? "months"
                  : ""
      ,
    },
    // {
    //   key: "Capital Return",
    //   value: "No",
    // },
    // {
    //   key: "Status",
    //   value: "Active",
    // },
  ];
  return (
    <>
      <Modal isActive={isActive} onClose={onClose} heading="Create Investments">
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Text primitive600 base>
              <span className={classes.note}>Note:</span> Your investment data
              will be displayed on your account dashboard after you complete the
              deposit.
            </Text>
          </div>
          <Text lg primitive950>
            You have selected{" "}
            <span className={classes.plan}>{position?.id}</span> investments
            plan.
          </Text>

          <div className={classes.depositInfo}>
            {depositInfo?.map((info, index) => (
              <div className={classes.spaceBetween} key={index}>
                <Text className={classes.key} primitive700 base>
                  {info.key}
                </Text>
                <Text
                  className={clsx(
                    classes.value,
                    info.value.toLowerCase() === "completed"
                      ? classes.completed
                      : info.value.toLowerCase() === "pending"
                        ? classes.pending
                        : info.value.toLowerCase() === "active"
                          ? classes.active
                          : info.value.toLowerCase() === "failed"
                            ? classes.failed
                            : ""
                  )}
                  primitive800
                  base
                  semiBold
                >
                  {info.value}
                </Text>
              </div>
            ))}
          </div>
          <div className={classes.inputWrapper}>
            <Text lg primitive950>
              Enter Amount
            </Text>
            <Input
              value={amount}
              setValue={setAmount}
              type="number"
              onKeyDown={handleKeyDown}
              className={clsx(classes.input, classes.amountInput)}
              placeholder="0.00"
            />
          </div>
          <div className={classes.policyContainer}>
            <Checkbox checked={agreeToPolicy} setChecked={setAgreeToPolicy} />
            <Text primitive700 base>
              I have read and agree with the{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Legal
              </a>{" "}
              and{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Policies
              </a>
              .
            </Text>
          </div>
          <div className={classes.buttonContainer}>
            <Button transparent onClick={onClose} className={classes.button}>
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                // onClose();
                onClick();
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateInvestmentsModal;
