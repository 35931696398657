import React, { useEffect, useState } from "react";
import classes from "./MyInvestments.module.css";
import clsx from "clsx";
import { Text } from "components/common";
import { FaArrowRightLong } from "react-icons/fa6";
import InvesmentsDetailsModal from "Modals/Dashboard/InvesmentsDetailsModal/InvesmentsDetailsModal";
import WithdrawModal from "Modals/Dashboard/WithdrawModal/WithdrawModal";
import TransactionDetailsModal from "Modals/Dashboard/TransactionDetailsModal/TransactionDetailsModal";
import { get_earnings } from "helpers/api/apis";

const SingleRow = ({ item, plans }) => {
  const [showInvestmentDetailsModal, setShowInvestmentDetailsModal] =
    useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);

  const [earnings, setEarnings] = useState(0);

  let plan = plans.find((plan) => plan.id === item.plan_id);

  async function fetchEarnings() {
    let { data } = await get_earnings(item.id);
    setEarnings(data);
  }

  useEffect(() => {
    fetchEarnings();
  }, []);

  return (
    <>
      <tr
        className={clsx(classes.row)}
        onClick={() => setShowInvestmentDetailsModal(true)}
      >
        <td className={classes.item}>{item.id.split("-")[4]}</td>
        <td className={classes.item}>
          <div className={classes.itemBox}>
            <Text
              sm
              semiBold
              primitive0
              uppercase
              font600
              className={classes.plan}
            >
              {plan?.name
              }
            </Text>
          </div>
        </td>
        <td className={classes.item}>{new Date(item.created_at_timestamp * 1000).toLocaleString()}</td>
        <td className={clsx(classes.item, classes.textRight)}>
          {item?.capital
          } USD
        </td>
        <td className={clsx(classes.item, classes.textRight)}>
          {item.reward_per_cycle} USD  {item.interest_schedule}
        </td>
        <td className={clsx(classes.item, classes.textRight)}>
          {earnings} USD
        </td>
        <td className={classes.item}>
          <div className={classes.statusContainer}>
            <Text
              primitive0
              sm
              font600
              className={clsx(
                classes.status,
                item.status.toLowerCase() === "completed"
                  ? classes.completed
                  : item.status.toLowerCase() === "active"
                    ? classes.active
                    : item.status.toLowerCase() === "pending"
                      ? classes.pending
                      : item.status.toLowerCase() === "failed"
                        ? classes.failed
                        : ""
              )}
            >
              {item.status}
            </Text>
          </div>
        </td>
        <td>
          {item.link && (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <FaArrowRightLong className={classes.arrow} />
            </a>
          )}
        </td>
      </tr>
      <InvesmentsDetailsModal
        item={item}
        isActive={showInvestmentDetailsModal}
        setShowWithdrawModal={setShowWithdrawModal}
        onClose={() => setShowInvestmentDetailsModal(false)}
      />
      <WithdrawModal
        isActive={showWithdrawModal}
        onClick={() => {
          setShowTransactionDetailsModal(true);
          setShowInvestmentDetailsModal(false);
        }}
        onClose={() => {
          setShowWithdrawModal(false);
          setShowInvestmentDetailsModal(false);
        }}
      />
      <TransactionDetailsModal
        isActive={showTransactionDetailsModal}
        onClose={() => setShowTransactionDetailsModal(false)}
      />
    </>
  );
};

export default SingleRow;
