import Wrapper from "components/common/Wrapper/Wrapper";

import React from "react";
import classes from "./InfoContainer.module.css";
import { Heading, Text } from "components/common";
import { Link } from "react-router-dom";
import { avatar1, avatar2, avatar3, avatar4, avatar5 } from "images";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, EffectCreative } from "swiper/modules";
import clsx from "clsx";

const InfoContainer = ({ frontendInfo }) => {
  const data = [
    {
      author: "Jane Doe",
      img: avatar1,
      userName: "@sarahmitchel",
      info: `Investing with ${frontendInfo?.site_name} has been a game-changer for me. The platform is secure, and the returns have exceeded my expectations`,
    },
    {
      author: "Carlos Ron",
      img: avatar2,
      userName: "@sarahmitchel",
      info: `I was new to investing, but ${frontendInfo?.site_name}  made it easy to get started and it is game-changer. The support team is fantastic!`,
    },
    {
      author: "Emily Willie",
      img: avatar3,
      userName: "@sarahmitchel",
      info: "The flexibility of the deposit plans and the transparency in transactions is what I love the most. The support team is awesome.",
    },
    {
      author: "Sarah Mitchel",
      img: avatar4,
      userName: "@sarahmitchel",
      info: `Investing with ${frontendInfo?.site_name} has been a game-changer for me. The platform is secure, and the returns have exceeded my expectations.`,
    },
    {
      author: "Sarah Mitchel",
      img: avatar5,
      userName: "@sarahmitchel",
      info: `I was new to investing, but ${frontendInfo?.site_name} made it easy to get started and it is game-changer. The support team is fantastic!`,
    },
  ];

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.content}>
        <Heading primitive0 xl4>
          Join Us Today!
        </Heading>
        <Text xl primitive200 semiBold className={classes.info}>
          Unlock Exclusive Benefits and Personalized Experiences. Sign up in
          seconds and start exploring a world tailored just for you.
        </Text>
        <Text lg primitive300 className={classes.helpContainer}>
          Need Help?
          <Link className={classes.link} to="/contact">
            Contact
          </Link>
        </Text>
        <Text lg primitive200>
          Trusted by over <span className={classes.bold}>5000</span> investors
          who have already joined and love the experience.
        </Text>
        <div className={classes.cards}>
          <Swiper
            loop={true}
            navigation={false}
            slidesPerView={1}
            effect={"fade"}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay, EffectCreative]}
            pagination={{
              clickable: true,
            }}
            scrollbar={{ hide: true }}
            className={clsx("collections-swiper", classes.swiper)}
          >
            {data.map((el, index) => (
              <SwiperSlide key={index}>
                <div className={classes.card}>
                  <Text lg primitive950>
                    {el.info}
                  </Text>
                  <div className={classes.authorInfo}>
                    <img src={el.img} alt="#" className={classes.authorImg} />
                    <div>
                      <Text semiBold lg primitive800>
                        {el.author}
                      </Text>
                      <Text sm primitive500>
                        {el.userName}
                      </Text>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
};

export default InfoContainer;
